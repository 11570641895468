import React from "react";
import styled from "styled-components";
import {
  Section,
  Container,
  Row,
  Col,
  Title,
  Box,
  BoxHeader,
  BoxHeaderImage,
  BoxBody,
  BoxSeparate,
} from "./style";
import iconeone from "../../../assets/svg/home/mid-level/ICONE-1_1-mid-level.png";
import iconetwo from "../../../assets/svg/home/mid-level/Icone-1_2-mid-level.png";
import iconetree from "../../../assets/svg/home/mid-level/Icone-1_3-mid-level.png";
import iconeonesecond from "../../../assets/svg/home/mid-level/Icone-2_1-mid-level.png";
import iconetwosecond from "../../../assets/svg/home/mid-level/Icone-2_2-mid-level.png";
import iconetreesecond from "../../../assets/svg/home/mid-level/Icone-2_3-mid-level.png";
import iconeonethird from "../../../assets/svg/home/mid-level/Icone-3_1-mid-level.png";
import iconesecondthird from "../../../assets/svg/home/mid-level/Icone-3_2-mid-level.png";
import iconetreethird from "../../../assets/svg/home/mid-level/Icone-3_3-mid-level.png";
import { useTranslation } from "react-i18next";
import Separate from "../../../assets/svg/separate-icon-service.svg";

const Highlight = styled.span`
  color: #00b6ba;
`;

const HighlightSectors = styled.span`
  color: #b9003e;
  display: inline;
  text-align: end;
`;

const HighlightPatents = styled.span`
  color: #f2b349;
`;

const MidLevel = () => {
  const { t } = useTranslation();

  const highlightText = (text, words, HighlightComponent) => {
    const parts = text.split(new RegExp(`(${words.join("|")})`, "gi"));
    return parts.map((part, index) =>
      words.some((word) => word.toLowerCase() === part.toLowerCase()) ? (
        <HighlightComponent key={index}>{part}</HighlightComponent>
      ) : (
        part
      )
    );
  };

  const comoFuncionaData = [
    {
      title: t("title_coverage"),
      items: [
        { text: t("text_coverage_1"), image: iconeone, category: "coverage" },
        { text: t("text_coverage_2"), image: iconetwo, category: "coverage" },
        { text: t("text_coverage_3"), image: iconetree, category: "coverage" },
      ],
    },
    {
      title: t("title_sectors"),
      items: [
        {
          text: t("text_sectors_1"),
          image: iconeonesecond,
          category: "sectors",
        },
        {
          text: t("text_sectors_2"),
          image: iconetwosecond,
          category: "sectors",
        },
        {
          text: t("text_sectors_3"),
          image: iconetreesecond,
          category: "sectors",
        },
      ],
    },
    {
      title: t("title_companies"),
      items: [
        {
          text: t("text_companies_1"),
          image: iconeonethird,
          category: "companies",
        },
        {
          text: t("text_companies_2"),
          image: iconesecondthird,
          category: "companies",
        },
        {
          text: t("text_companies_3"),
          image: iconetreethird,
          category: "companies",
        },
      ],
    },
  ];

  const getHeight = (category) => {
    switch (category) {
      case "coverage":
        return "50px";
      case "sectors":
        return "150px";
      case "companies":
        return "100px";
      default:
        return "auto";
    }
  };

  const getBorderColor = (category) => {
    switch (category) {
      case "coverage":
        return "#00B6BA";
      case "sectors":
        return "#f14668";
      case "companies":
        return "#05036f";
      default:
        return "#CCCCCC";
    }
  };

  return (
    <Section className="como-funciona">
      <BoxSeparate>
        <img src={Separate} alt="Separate Icon" />
      </BoxSeparate>
      <Container>
        {comoFuncionaData.map((data, dataIndex) => (
          <React.Fragment key={dataIndex}>
            <Row>
              <Col>
                <Title className="cl-azul">
                  {data.title.toLowerCase().includes("setores econômicos") ||
                  data.title.toLowerCase().includes("economic sectors") ||
                  data.title.toLowerCase().includes("sectores económicos")
                    ? highlightText(
                        data.title,
                        [
                          "setores econômicos",
                          "economic sectors",
                          "sectores económicos",
                        ],
                        HighlightSectors
                      )
                    : data.title.toLowerCase().includes("patentes") ||
                      data.title.toLowerCase().includes("patents")
                    ? highlightText(
                        data.title,
                        ["patentes", "patents"],
                        HighlightPatents
                      )
                    : highlightText(
                        data.title,
                        ["cobertura", "coverage"],
                        Highlight
                      )}
                </Title>
              </Col>
            </Row>
            <Row>
              {data.items.map((item, itemIndex) => (
                <Col key={itemIndex}>
                  <Box borderColor={getBorderColor(item.category)}>
                    <BoxHeader>
                      <BoxHeaderImage
                        src={item.image}
                        alt={`Imagem ${dataIndex + 1}-${itemIndex + 1}`}
                      />
                    </BoxHeader>
                    <BoxBody height={getHeight(item.category)} className="p2">{item.text}</BoxBody>
                  </Box>
                </Col>
              ))}
            </Row>
          </React.Fragment>
        ))}
      </Container>
      <BoxSeparate>
        <img src={Separate} alt="Separate Icon" />
      </BoxSeparate>
    </Section>
  );
};

export default MidLevel;
