import React, { useRef, useState } from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import Input from "../../components/Input";
import InTextLink from "../../components/InTextLink";
import Panel from "../../components/Panel";
import ShowPasswordButton from "../../components/ShowPasswordButton";
import { ContentContainer, RowWrapper } from "../../shared/style";
import theme from "../../shared/theme";
import replaceJSX from "../../utils/translateWithJSX";
import { LoginRow, PasswordRow, TitleRow } from "./style";
import { Form } from "@unform/web";
import * as yup from "yup";
import { useAuth } from "../../contexts/AuthContext";
const Login = () => {
  const { t } = useTranslation();
  const formRef = useRef(null);
  const { handleLogin } = useAuth();
  const [passwordShown, setPasswordShown] = useState(false);
  const validateFieldsToSubmit = async (data) => {
    try {
      formRef.current.setErrors({});

      const loginScheme = yup.object().shape({
        email: yup
          .string()
          .email(t("form_error_email_invalid"))
          .required(t("form_error_email_required")),
        password: yup.string().required(t("form_error_password_required")),
      });

      await loginScheme.validate(data, {
        abortEarly: false,
      });

      await handleLogin(data);
      // handleLogin({ email: "zalequ@spicethainj.com", password: "123456" });
    } catch (error) {

      const validationErrors = {};

      if (error instanceof yup.ValidationError) {
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        formRef.current.setErrors(validationErrors);
      }

      formRef.current.setErrors({
        email: error.message
      })
    }
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <ContentContainer>
      <Grid>
        <RowWrapper middle="md">
          <Col lg={8}>
            <TitleRow>
              <h3>{t("page_login_make_your_login")}</h3>
            </TitleRow>
          </Col>
          <Col lg={4}>
            <Panel>
              <Grid>
                <Col>
                  <LoginRow>
                    <h1 style={{ color: theme.colors.blue.default }}>
                      {t("lbl_login")}
                    </h1>
                  </LoginRow>
                  <Form ref={formRef} onSubmit={validateFieldsToSubmit}>
                    <LoginRow>
                      <Input
                        placeholder={t("ph_login_email")}
                        label={t("lbl_email")}
                        name="email"
                      />
                    </LoginRow>

                    <PasswordRow>
                      <Input
                        type={passwordShown ? "text" : "password"}
                        placeholder={t("ph_password")}
                        label={t("lbl_password")}
                        name="password"
                        onChange={(value) => console.log(value)}
                      />
                      <ShowPasswordButton
                        passwordShown={passwordShown}
                        onTogglePassword={togglePassword}
                      />
                    </PasswordRow>

                    <LoginRow>
                      <InTextLink
                        fontWeight={theme.fontWeights.regular}
                        fontSize={theme.fontSizes.p2}
                        label={t("lbl_recovery_password")}
                        to="/forgot_password"
                      />
                    </LoginRow>

                    <Row center="lg">
                      <Col lg={7}>
                        <Button
                          type="submit"
                          width="100%"
                          size="large"
                          label={t("lbl_login")}
                        />
                      </Col>
                    </Row>
                  </Form>

                  <Row center="lg">
                    <p
                      style={{
                        fontSize: theme.fontSizes.p2,
                        marginTop: "0.5em",
                      }}
                    >
                      {replaceJSX(
                        t("page_login_signup"),
                        "%",
                        <InTextLink
                          fontWeight={theme.fontWeights.regular}
                          fontSize={theme.fontSizes.p2}
                          label={t("lbl_registration")}
                          to="/signup"
                        />
                      )}
                    </p>{" "}
                  </Row>
                </Col>
              </Grid>
            </Panel>
          </Col>
        </RowWrapper>
      </Grid>
    </ContentContainer>
  );
};

export default Login;
