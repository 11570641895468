import styled from "styled-components";
import { Flex } from "../../shared/style";

export const DefaultPanel = styled(Flex)`
  flex-direction: ${(props) => (props.hasHeader ? "column" : "row")};
  background-color: ${(props) => props.theme.colors.light.pureWhite};
  padding: ${(props) => (props.hasHeader ? "1em" : "1.5em")};
  border-radius: 10px;
  box-shadow: 4px 4px 4px rgba(86, 105, 129, 0.2);

  @media (max-width: 640px) {
    padding: 1.5rem 1rem;
  }
`;

export const Header = styled(Flex)`
  justify-content: space-between;
  padding-bottom: 0.5em;
  border-bottom: 1px solid ${(props) => props.theme.colors.blue.default};

  & h1 {
    color: ${(props) => props.theme.colors.blue.default};
  }

  @media (max-width: 420px) {
    h1 {
      font-size: ${(props) => props.theme.fontSizes.p1};
    }
  }
`;
