import styled from "styled-components";
import { Flex } from "../../shared/style";
import {
  getInputBoxShadow,
  getInputColors,
  getInputTextColors,
} from "../../utils/inputColors";

export const InputDefault = styled(Flex)`
  flex-direction: column;
  width: 100%;

  & svg {
    outline: none;
    margin-left: 0.25em;
  }

  & p {
    color: ${(props) => getInputTextColors(props)};
  }

  & input {
    display: block;
    padding: 0.5em;
    margin-top: 0.25em;
    border: none;
    border-radius: 4px;
    transition: box-shadow 0.1s linear;
    color: ${(props) => getInputTextColors(props)};
    background-color: ${(props) => props.theme.colors.light.default};
    box-shadow: inset 0 0 0 1px ${(props) => getInputColors(props)};

    &::placeholder {
      color: ${(props) => props.theme.colors.light.dark};
    }

    &:hover {
      box-shadow: inset 0 0 0 2px ${(props) => getInputColors(props)},
        ${(props) => getInputBoxShadow(props)};
    }

    &:focus {
      box-shadow: inset 0 0 0 2px ${(props) => getInputColors(props)},
        4px 4px 8px rgba(0, 0, 27, 0.1);
    }
  }

  & small {
    margin-top: 0.5em;
    color: ${(props) => getInputTextColors(props)};
    text-align: start;
  }
`;
