import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import Backend from "i18next-http-backend";

export const i18nKey = "i18nextLng";

i18n
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .use(LanguageDetector)
  .init({
    debug: false,
    detection: {
      order: [
        "localStorage",
        "querystring",
        "cookie",
        "sessionStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      lookupLocalStorage: i18nKey,
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
    },
    fallbackLng: "pt_BR",
    whitelist: ["pt_BR", "es", "en"],
    // whitelist: ["en", "pt_BR", "es"],

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    backend: {
      loadPath: "/locales/{{lng}}/translation.json",
    },
  });

export default i18n;
