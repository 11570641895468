import React, { useEffect, useState } from "react";
import { ReactComponent as GridIcon } from "../../assets/svg/grid-icon.svg";
import { useTranslation } from "react-i18next";
import { SearchExplain, SearchPanel } from "../../pages/Search/style";
import { Flex } from "../../shared/style";
import Select from "../Select";
import { api321go } from "../../api/api321go";
import i18next from "i18next";

const descriptionKeys = {
  pt_BR: "description_pt_br",
  es: "description_es",
  en: "description_en",
};

const TechField = () => {
  const [techFields, setTechFields] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    async function getTechFields() {
      try {
        const { data } = await api321go.get("/techfields");
        setTechFields(parseLabels(data));
      } catch (error) {
        console.log(error);
      }
    }

    getTechFields();
  }, []);

  useEffect(() => {
    setTechFields(parseLabels(techFields));
  }, [i18next.language]);

  const parseLabels = (data) => {
    const newTechFields = data;

    newTechFields.forEach((item) => {
      item = Object.assign(item, {
        value: item.subclass_code,
        label: `${item.subclass_code} - ${item[descriptionKeys[i18next.language]]}`,
      });
    });

    return newTechFields;
  };

  return (
    <SearchPanel icon={GridIcon} header={t("lbl_tech_field")}>
      <SearchExplain style={{ marginBottom: "2em" }}>
        {t("serach_page_tech_field_explain")}
      </SearchExplain>
      <Flex>
        <Select
          placeholder={t("ph_tech_field")}
          label={t("lbl_tech_field")}
          name="tech_field"
          options={techFields}
        />
      </Flex>
    </SearchPanel>
  );
};

export default TechField;
