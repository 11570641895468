
import React from "react";
import { Eye, EyeOff } from "react-feather";
import { ShownPasswordButton } from "./style";

const ShowPasswordButton = ({ passwordShown, onTogglePassword }) => {
  return (
    <ShownPasswordButton
      type="button"
      onClick={onTogglePassword}
    >
      {passwordShown ? <Eye size={16} /> : <EyeOff size={16} />}
    </ShownPasswordButton>
  );
};

export default ShowPasswordButton;