import theme from "../shared/theme";

export const getButtonColor = (props) => {
  if (props.disabled) {
    return props.theme.colors.black.default;
  }

  if (props.buttonStyle === "outlined") {
    return props.theme.colors.blue.default;
  }

  return props.theme.colors.light.default;
};

export const getButtonBackground = (props) => {
  if (props.disabled) {
    return props.theme.colors.light.dark;
  }

  if (props.buttonStyle === "outlined") {
    return "transparent";
  }

  return props.theme.colors.blue.default;
};

export const getBorderColor = (props) => {
  if (props.buttonStyle === "outlined") {
    if (props.disabled) {
      return props.theme.colors.blue.default;
    } else {
      return props.theme.colors.blue.default;
    }
  }

  return "unset";
};

export const getIconStroke = (props) => {
  if (props.disabled) {
    return theme.colors.black.default;
  }

  if (props.buttonStyle === "outlined") {
    return theme.colors.blue.default;
  }

  return theme.colors.light.default;
};
