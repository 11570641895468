import React from "react";
import Panel from "../Panel";
import { ReactComponent as MoneyIcon } from "../../assets/svg/money-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/svg/edit-icon.svg";
import { useTranslation } from "react-i18next";
import IconButton from "../IconButton";
import { Col, Row } from "react-flexbox-grid";
import { SubscriptionGrid } from "./style";

const Subscription = ({ subscription }) => {
  const { t } = useTranslation();

  return (
    <Panel
      icon={MoneyIcon}
      header={`${t("lbl_subscription_info")}`}
      margin="1em 0 0 0"
      action={
        <IconButton icon={EditIcon} onClick={() => {}} />
      }
    >
      <SubscriptionGrid>
        <Col lg={12}>
          <Row style={{ display: "flex", alignItems: "center" }}>
            <p>{t("lbl_status")}:</p>
            <span>{subscription?.status}</span>
          </Row>
          <Row style={{ display: "flex", alignItems: "center" }}>
            <p>{t("lbl_plan")}:</p>
            <span>{subscription?.plan}</span>
          </Row>
          <Row style={{ display: "flex", alignItems: "center" }}>
            <p>{t("lbl_days_left")}:</p>
            <span>{subscription?.validUntil}</span>
          </Row>
        </Col>
      </SubscriptionGrid>
    </Panel>
  );
};

export default Subscription;
