// style.js
import styled from 'styled-components';

export const Section = styled.section`
  margin-bottom: 56px;
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;


export const Input = styled.input`
  width: 100%;
  padding: 0.5rem 2.5rem 0.5rem 0.5rem; 
  border: 2px solid #000043;
  border-radius: 5px;
  box-sizing: border-box;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const RowContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 2rem;
`

export const Col = styled.div`
  flex: 1;
  padding: 15px;
`;

export const BlogListaContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Alinha o conteúdo ao centro */
  text-align: center; /* Centraliza o texto */
`;

export const DisplayTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center; /* Centraliza as tags */
  margin: 8px 0; /* Adiciona espaçamento entre as tags e o meta */
  span {
    background-color:#00B6BA;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.875rem;
  }
`;

export const BlogTitle = styled.h5`
  font-size: 1.25rem;
  margin: 16px 0;
  a {
    text-decoration: none;
    color: #003366;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const BlogMeta = styled.span`
  font-size: 0.875rem;
  color: #666;
  margin-bottom: 8px;
`;

export const BlogImage = styled.img`
  width: 100%; 
  height: auto;
  max-width: 100%; 
  border-radius: 8px;
  margin-bottom: 16px;
`;

export const DestaqueContent = styled(BlogListaContent)`
  margin-bottom: 32px; 
`;

export const DestaqueImage = styled(BlogImage)`
  width: 50%;
  height: auto;
  max-width: 100%;
  margin: 0 auto; 
  display: block;
`;

export const OtherPostsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px; 
  margin-top: 16px; 
`;


export const Separator = styled.img`
  width: 100%;
  margin: 1rem auto;

`;

export const InputWrapper = styled.div`
  position: relative;
  width: 300px;
`;


export const SearchIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
`;