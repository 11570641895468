import React from "react";
import Panel from "../Panel";
import { ReactComponent as ProfileIcon } from "../../assets/svg/profile-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/svg/edit-icon.svg";
import { useTranslation } from "react-i18next";
import IconButton from "../IconButton";
import { Col, Row } from "react-flexbox-grid";
import { ProfileGrid } from "./style";
const Profile = ({ user }) => {
  const { t } = useTranslation();

  const styleConfig = { display: "flex", alignItems: "center" };

  return (
    <Panel
      icon={ProfileIcon}
      header={t("lbl_profile")}
      margin="0 0 1em 0"
      action={
        <IconButton icon={EditIcon} onClick={() => { }} />
      }
    >
      <ProfileGrid>
        <Col lg={12}>
          <Row style={styleConfig}>
            <p>{t("lbl_name")}:</p>
            <span>{user?.user_name}</span>
          </Row>
          <Row style={styleConfig}>
            <p>{t("lbl_function")}:</p>
            <span>{t(user?.occupation.en.toLowerCase())}</span>
          </Row>
          <Row style={styleConfig}>
            <p>{t("lbl_email")}:</p>
            <span>{user?.email}</span>
          </Row>
          <Row style={styleConfig}>
            <p>{t("lbl_organization_type")}:</p>
            <span>{t(user?.organization_type.en.toLowerCase())}</span>
          </Row>
        </Col>
      </ProfileGrid>
    </Panel>
  );
};

export default Profile;
