import React from 'react';
import Loader from "../../components/LoadScreen";
import { DownloadContainer } from "./style";

const Download = () => {

  return (
    <>
      <Loader active />
      <DownloadContainer>
        Download
      </DownloadContainer>
    </>
  );
};

export default Download;