import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { ThemeProvider } from "styled-components";
import App from "./App";
import "./i18n";
import { GlobalStyle } from "./shared/style";
import theme from "./shared/theme";
import store from './store'
import {Provider, useSelector} from 'react-redux'
import Loader from "./components/LoadScreen";

ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={<div />}>
        <I18nextProvider>
          <ThemeProvider theme={theme}>
            <React.StrictMode>
              <App />
              <GlobalStyle />
            </React.StrictMode>
          </ThemeProvider>
        </I18nextProvider>
      </Suspense>
    </Provider>,
  document.getElementById("root")
);
