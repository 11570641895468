import styled from "styled-components";
import { Flex } from "../../shared/style";
import { getLabelColor } from "../../utils/selectColors";

export const DefaultSelect = styled(Flex)`
  flex-direction: column;
  width: 100%;

  & svg {
    outline: none;
    margin-left: 0.25em;
  }

  & p {
    color: ${(props) => getLabelColor(props)};
  }

  & small {
    margin-top: 0.5em;
    color: ${(props) => getLabelColor(props)};
    text-align: start;
  }
`;
