import "./style";
import { BoxDots, Dot1, Dot2, Dot3, Dot4 } from "./style";


const ColorDots = () => {
  return(
    <>
    <BoxDots>
      <Dot1 />
      <Dot2 />
      <Dot3 />
      <Dot4 />

    </BoxDots>
    </>
  )


}
export default ColorDots