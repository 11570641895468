import React from "react";
import { Flex } from "../../shared/style";
import theme from "../../shared/theme";
import { DefaultPanel, Header } from "./style";

const Panel = ({ children, header, icon, action, ...props }) => {
  const Icon = icon ? icon : null;
  return (
    <DefaultPanel hasHeader={header} {...props}>
      {header && (
        <Header>
          <Flex alignItems="center">
            {Icon && (
              <Icon
                width="24"
                height="24"
                style={{ marginRight: "0.5em" }}
                stroke={theme.colors.blue.default}
              />
            )}
            <h1>{header}</h1>
          </Flex>

          {action && <Flex alignItems="center">{action}</Flex>}
        </Header>
      )}
      {children}
    </DefaultPanel>
  );
};

export default Panel;
