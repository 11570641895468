import React, { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import Profile from "../../components/Profile";
import SearchHistory from "../../components/SearchHistory";
import Subscription from "../../components/Subscription";
import { DashboardContainer, ColTable } from "./style";
import Loader from "../../components/LoadScreen";
import { useAuth } from "../../contexts/AuthContext";
import { useSearch } from "../../contexts/SearchContext";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const [subscription, setSubscription] = useState();
  const [searchHistory, setSearchHistory] = useState([]);
  const { loadingMap, setLoadingMap } = useSearch();
  const { t } = useTranslation();
  const [user, setUser] = useState({
    "id": 0,
    "user_name": "",
    "email": "",
    "occupation": {
      "en": "",
      "pt": "",
      "es": ""
    },
    "organization_type": {
      "en": "",
      "pt": "",
      "es": ""
    }
  });
  const {
    loadingUser,
    handleUserData,
    loadingSearchHistory,
    handleSearchHistory,
  } = useAuth();

  useEffect(() => {
    loadUserData();
    setLoadingMap(false);
    updateSearchHistory();
  }, []);

  const loadUserData = async () => {
    let data = await handleUserData();
    let lang = localStorage.getItem("i18nextLng");

    if (lang === "pt_BR") {
      lang = "pt"
    }

    setUser(data);
    setSubscription({
      status: data["status_plan"][lang], plan: data["plan"][lang], validUntil: data["valid_until"]
    });
  };

  const updateSearchHistory = async () => {
    try {
      const data = await handleSearchHistory()
      setSearchHistory(data);
    } catch (error) {
      setSearchHistory([]);
    }
  }

  return (
    <>
      {loadingMap
        ? <Loader active={loadingMap} message={t("lbl_search_loading")} />
        : <Loader
          active={loadingUser && loadingSearchHistory} />
      }

      <DashboardContainer fluid>
        <Row>
          <Col lg={4}>
            <Row>
              <Col lg={12}>
                <Profile user={user} />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Subscription subscription={subscription} />
              </Col>
            </Row>
          </Col>
          <ColTable lg={8}>
            <SearchHistory data={searchHistory} />
          </ColTable>
        </Row>
      </DashboardContainer>
    </>
  );
};

export default Dashboard;
