import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { Grid, Row, Col } from "react-flexbox-grid";

export const HeaderContainer = styled(Grid)`
  background-color: ${(props) => props.theme.colors.light.pureWhite};
  box-shadow: 4px 4px 8px rgba(0, 0, 27, 0.1);
  position: sticky;
  top: 0;
  z-index: 5;
`;

export const HeaderColWrapper = styled(Col)`
  @media(max-width: 768px) {
    display: ${(props) => props.active ? 'block' : 'none'};
  }
`

export const HeaderLink = styled.a`
  text-decoration: none;
  margin: 0 1em;

  &:active,
  &:link,
  &:visited {
    color: ${(props) => props.theme.colors.black.default};
    font-weight: 400;
  }
  &.active {
    color: ${(props) => props.theme.colors.blue.default};
    font-weight: 600;
  }

  &:hover {
    color: ${(props) => props.theme.colors.blue.default};
  }

  @media(max-width: 768px) {
    display: block;
    margin: 1rem auto;

    &:hover {
      color: ${(props) => props.theme.colors.seaGreen.dark};
    }
  }
`;

export const HeaderNav = styled(NavLink)`
  margin: 0 1em;

  &:active,
  &:link,
  &:visited {
    color: ${(props) => props.theme.colors.black.default};
    font-weight: 400;
  }
  &.active {
    color: ${(props) => props.theme.colors.blue.default};
    font-weight: 600;
  }

  &:hover {
    color: ${(props) => props.theme.colors.blue.default};
  }

  @media(max-width: 768px) {
    display: block;
    margin: 1rem auto;

    &:hover {
      color: ${(props) => props.theme.colors.seaGreen.dark};
    }
  }
`;

export const RowHeader = styled(Row)`
  display: ${(props) => props.active ? 'block' : 'flex'};

  ${(props) => !props.active && css`
    align-items: center;
    justify-content: center;
  `}
`;


export const MenuColWrapper = styled(Col)`
  display: none;

  @media(max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

export const MenuButton = styled.button`
  width: 2.25rem;
  height: 2.25rem;
  padding: 0.25rem;
  border: 0;
  cursor: pointer;
  border-radius: 6px;
  background-color: transparent;

  &:hover {
    background-color: ${(props) => props.theme.colors.light.dark}30;
  }
`;