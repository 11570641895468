import { Col, Grid, Row } from "react-flexbox-grid";
import styled from "styled-components";

export const FooterContainer = styled(Grid)`
  background-color: ${(props) => props.theme.colors.blue.default};
  color: ${(props) => props.theme.colors.light.default};
`;

export const TopFooter = styled(Col)`
  border-bottom: 1px solid ${(props) => props.theme.colors.blue.light};
  padding-top: 1em;
  padding-bottom: 1em;

  & p {
    font-weight: 600;
  }

  & a {
    &:active,
    &:link,
    &:visited {
      color: ${(props) => props.theme.colors.light.default};
      font-weight: 400;
    }
    &.active {
      color: ${(props) => props.theme.colors.light.default};
      font-weight: 600;
    }
  }
`;

export const BottomFooter = styled(Col)`
  padding-top: 1em;
  padding-bottom: 0.5em;

  & img {
    margin-right: 0.5em;
  }
`;

export const SocialRow = styled(Row)`
  & img {
    margin-right: 2em;
  }
`;

export const FooterRow = styled(Row)`
  @media (max-width: 420px) {
    div + div {
      margin-top: 1em;
    }
  }
`
