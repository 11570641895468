import React, { useState } from 'react';
import { Section, Container, Row, Col, Title, TitleSecond, FAQContent, FAQImage, ImageIcon } from './style';
import faqImage from '../../../assets/svg/home/faq/img-fqa.png';
import IconMore from '../../../assets/svg/home/faq/Icons-more.png'; 
import IconLess from '../../../assets/svg/home/faq/icons-less.png'; 
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: t("faq_what_is_321go"),
      answer: t("faq_what_is_321go_answer"),
    },
    {
      question: t("faq_how_to_register"),
      answer: t("faq_how_to_register_answer"),
    },
    {
      question: t("faq_access_services"),
      answer: t("faq_access_services_answer"),
    },
    {
      question: t("faq_plan_active_duration"),
      answer: t("faq_plan_active_duration_answer"),
    },
    {
      question: t("faq_search_history_duration"),
      answer: t("faq_search_history_duration_answer"),
    },
  ];

  return (
    <Section>
      <Container>
        <Row>
          <Col className="col-7">
          <Title>{t("lbl_clarify_doubts")}</Title>
          <TitleSecond>{t("lbl_contact_us_doubt")}</TitleSecond>
            {faqs.map((faq, index) => (
              <FAQContent key={index} className={activeIndex === index ? 'active' : ''}>
                <div className="faq-header">
                  <h3>{faq.question}</h3>
                  <img 
                    src={activeIndex === index ? IconLess : IconMore} 
                    alt={activeIndex === index ? "Icon Less" : "Icon More"} 
                    onClick={() => toggleFAQ(index)} 
                  />
                </div>
                <p>{faq.answer}</p>
              </FAQContent>
            ))}
          </Col>
          <Col className="col-5 image">
            <FAQImage>
              <ImageIcon src={faqImage} alt="FAQ" />
            </FAQImage>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default FAQ;