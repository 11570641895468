import theme from "../shared/theme";

export const getInputColors = (props) => {
  if (props.error) {
    return theme.colors.sweetRed.default;
  }

  if (props.disabled) {
    return theme.colors.light.dark;
  }

  return theme.colors.blue.default;
};

export const getInputTextColors = (props, isPlaceholder = false) => {
  if (props.error) {
    return theme.colors.sweetRed.dark;
  }

  if (props.disabled || isPlaceholder) {
    return theme.colors.light.dark;
  }

  return theme.colors.black.default;
};

export const getInputBoxShadow = (props) => {
  if (props.disabled) {
    return "unset";
  }

  return "4px 4px 8px rgba(0, 0, 27, 0.25)";
};
