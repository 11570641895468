import { Row } from "react-flexbox-grid";
import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    font-family: 'Hind Vadodara', sans-serif;
  }

  body,
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    text-rendering: optimizeLegibility;
    color: ${(props) => props.theme.colors.black.default};
    background-color: ${(props) => props.theme.colors.light.default};
  }

  @media (max-width: 720px) {
    html {
      font-size: 87.5%;
    }
  }

  input {
    outline: none;
  }

  button {
    &:active {
      outline: none;
    }
  }

  h1 {
    font-size: ${(props) => props.theme.fontSizes.h1};
  }

  h2 {
    font-size: ${(props) => props.theme.fontSizes.h2};
  }

  h3 {
    font-size: ${(props) => props.theme.fontSizes.h3};
    font-weight: 400;
  }

  h1,
  h2 {
    font-weight: 600;
  }

  p {
    font-size: ${(props) => props.theme.fontSizes.p1};
  }

  small {
    font-size: ${(props) => props.theme.fontSizes.caption};
  }

  a {
    text-decoration: none;
  }

  li {
    text-align: start;
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  flex-wrap: ${(props) => props.wrap};
  padding: ${(props) => props.padding};
  gap: ${(props) => props.gap};
`;

export const MenuButton = styled.button`
  background-color: transparent;
  border: none;
  height: 24px;
  cursor: pointer;
  font-weight: 600;
  line-height: 0;
  font-size: ${props => props.theme.fontSizes.p2};
  color: ${props => props.theme.colors.blue.default};

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
`;

export const RowWrapper = styled(Row)`
  padding: 0.5em 2em;
  margin: 0;

  @media(max-width:640px) {
    padding: 0.5em 0;
  }
`;

export const ContentContainer = styled(Flex)`
  min-height: calc(100vh - 70px);
  overflow: auto;
  align-items: center;
  justify-content: center;
`;
