import styled from 'styled-components';

export const Section = styled.section`
  margin-bottom: 56px;
`;

export const DivItens = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  
  .line {
    margin-top: -4rem;
    margin-bottom: 2rem;
    width: 50%;
    @media(max-width: 1200px){
    width: 80%
  }

    @media (max-width: 768px) {
      width: 80%;
    }

    @media (max-width: 480px) {
      width: 90%;
    }
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

export const Person = styled.div`
  display: flex;
  margin-bottom: 2rem;
  width: 50%;

  @media(max-width: 1200px){
    width: 100%
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 50%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 50%;
  }

  &.fernanda-reis {
    flex-direction: row-reverse;

    @media (max-width: 768px) {
      flex-direction: column;
      width: 50%;
    }
  }
`;

export const PersonImage = styled.img`
  border-radius: 0.2rem;
  width: 30%;
  height: auto; /* Mantém a proporção da imagem */
  margin-right: 2rem;
  border-radius: 0.8rem;
  

  @media (max-width: 768px) {
    margin-bottom: 1rem;
    margin-right: 0;
    width: 50%;
  }

  &.fernanda-reis {
    margin-right: 0;
    margin-left: 2rem;
    border-radius: 0.8rem;
    @media (max-width: 768px) {
      margin-left: 0;
      width: 50%;
    }
  }
`;
export const PersonText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
  width: 75%;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
  }

  h3 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  p {
    margin-top: 0.5rem;
    font-size: 18px;
    line-height: 27px;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const Separator = styled.hr`
  width: 50%;
  border: 1px solid #F2B349;
  margin: 1rem auto;

  @media (max-width: 768px) {
    width: 80%;
  }
`;