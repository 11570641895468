import React from "react";
import { ReactComponent as KeyIcon } from "../../assets/svg/key-icon.svg";
import { useTranslation } from "react-i18next";
import { SearchExplain, SearchPanel } from "../../pages/Search/style";
import Input from "../../components/Input";
import Select from "../../components/Select";
import { SearchFormRow } from "./style";

const KeyWords = () => {
  const { t } = useTranslation();
  const bools = [
    { label: t("lbl_and"), value: "and" },
    { label: t("lbl_or"), value: "or" },
  ];

  return (
    <SearchPanel icon={KeyIcon} header={t("lbl_keywords")}>
      <SearchExplain>{t("serach_page_key_word_explain")}</SearchExplain>
      <SearchFormRow>
        <Input
          label={t("lbl_keyword")}
          name="keyword01"
          tip={t("keyword_tooltip")}
          placeholder={t("ph_keyword")}
        />
      </SearchFormRow>

      <SearchFormRow>
        <Input
          label={t("lbl_keyword")}
          name="keyword02"
          tip={t("keyword_tooltip")}
          placeholder={t("ph_keyword")}
        />
      </SearchFormRow>

      <SearchFormRow>
        <Input
          label={t("lbl_keyword")}
          name="keyword03"
          tip={t("keyword_tooltip")}
          placeholder={t("ph_keyword")}
        />
      </SearchFormRow>
    </SearchPanel>
  );
};

export default KeyWords;
