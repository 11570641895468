import styled from "styled-components";

export const ShownPasswordButton = styled.button`
  border: 0;
  position: absolute;
  top: 39px;
  right: 20px;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${(props) => props.theme.colors.blue.default}
  }
`;
