import styled from 'styled-components';

export const Section = styled.section`
`;

export const Container = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: left;

  @media (max-width: 870px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 640px) {
    gap: 1rem;
  }
`;

export const Col = styled.div`
  flex: 1;

  padding: 0 15px;

  @media (max-width: 870px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 640px) {
    max-width: 100%;
  }
`;

export const Title = styled.h3`
  margin-bottom: 32px;
  font-weight: 600;
  line-height: 66px;
  font-size: 2.3rem;
  color: #05036f;
  @media (max-width: 420px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

export const Box = styled.div`
  width: 350px;
  border-bottom: 4px solid ${(props) => props.borderColor || '#CCCCCC'};
  padding: 15px;
  margin-bottom: 30px;
  box-shadow: 4px 4px 4px rgba(5, 3, 111, 0.15);
`;

export const BoxHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export const BoxHeaderImage = styled.img`
  max-width: 15%;
  height: auto;
  border-radius: 10px;
`;

export const BoxHeaderTitle = styled.div`
  padding: 1rem;
  color: #00B6BA;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: left;
`;

export const BoxBody = styled.div`
  height: ${(props) => props.height || '150px'};
  padding: 1rem 0px;
  color: #333;
  font-size: 1rem;
  text-align: left;

  @media (max-width: 870px) {
    height: auto;
  }
`;

export const BoxSeparate = styled.div`
    display: flex;
    justify-content: center;
    alignItems: center;
    margin-top: 56px;
    margin-bottom: 56px;
`;