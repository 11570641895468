import React, { useRef, useEffect } from "react";
import { Flex } from "../../shared/style";
import { InputDefault } from "./style";
import { ReactComponent as TipIcon } from "../../assets/svg/tip-icon.svg";
import { getInputTextColors } from "../../utils/inputColors";
import ReactTooltip from "react-tooltip";
import { useField } from "@unform/core";

const Input = ({ name, value, placeholder, label, disabled, tip, type, onInput = () => {} }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = "", registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <InputDefault disabled={disabled} error={error}>
      <Flex>
        <p>{label}</p>
        {tip && (
          <>
            <TipIcon
              data-tip={tip !== "override" && tip}
              width="24"
              height="24"
              stroke={getInputTextColors({ disabled, error })}
            />
            {tip !== "override" && (
              <ReactTooltip place="top" effect="solid" multiline />
            )}
          </>
        )}
      </Flex>
      <input
        ref={inputRef}
        type={type}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        placeholder={placeholder}
        onInput={onInput}
      />
      {error && <small>{error}</small>}
    </InputDefault>
  );
};

export default Input;
