import { ArrowLeft, ArrowRight } from "react-feather";

import { ArrowContainer } from "./style";

const Arrow = ({ disabled, left, onCurrentSlideChange }) => {

  return (
    <ArrowContainer
      disabled={disabled}
      onClick={onCurrentSlideChange}
    >
      {left && (
        <ArrowLeft strokeWidth={3} size={20} />
      )}
      {!left && (
        <ArrowRight strokeWidth={3} size={20} />
      )}
    </ArrowContainer>
  )
}

export default Arrow;