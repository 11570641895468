import React from "react";
import { DefaultRadioButton } from "./style";

const RadioButton = ({ name, label, disabled, checked = false, onClick = () => {} }) => {
  return (
    <DefaultRadioButton disabled={disabled}>
      {label}
      <input type="radio" name={name} disabled={disabled} checked={checked} onClick={onClick} />
      <span></span>
    </DefaultRadioButton>
  );
};

export default RadioButton;
