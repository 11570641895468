import styled from 'styled-components';

export const Section = styled.section`
  margin-bottom: 56px;
`;

export const Header = styled.header`
  background-color: #05036F;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

`;


export const Slide = styled.div`
  background-color: #05036F;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 100px; /* Adiciona padding inferior para expandir o fundo azul */

`;

export const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const SlideChamada = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin: auto;
  padding-left: 4rem;

  @media (max-width: 768px) {
    padding-left: 2rem;
  }

  @media (max-width: 420px) {
    padding: 1rem;
    justify-content: center;
  }
`;

export const Title = styled.h1`
  color: #F2B349;
  font-style: normal;
  font-weight: 600;
  line-height: 66px;
  position: relative;
  width: 75%;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #F2B349;
    position: absolute;
    bottom: -10px;
    left: 0;
  }

  @media (max-width: 768px) {
    font-size: 40px;
    line-height: 55px;
  }

  @media (max-width: 420px) {
    font-size: 33px;
    line-height: 50px;
  }
`;

export const Excerpt = styled.div`
  color: #F9F9F9;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  margin: 2rem 0;
  padding-bottom: 1rem;
  width: 100%;

  p {
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 420px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const SlideImage = styled.div`
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    margin-top: 2rem;
    margin-bottom: 0;
    width: 75%;
    display: flex;
    justify-content: end;
  }

  @media (max-width: 420px) {
    width: 100%;
    justify-content: center;
  }
`;

export const Image = styled.img`
  border-radius: 0 10px 0 0;
  width: 100%;
  height: auto;
`;

export const DivRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 75%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .item{
    display: 'flex';
    flex-direction: 'row';
    align-items: 'center';
    width: 70%
  }
`;

export const BodyContainer = styled.div`

display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: nowrap;
    flex-direction: column;

`