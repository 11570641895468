import styled from "styled-components";
import { ContentContainer } from "../../shared/style";

export const SuccessContainer = styled(ContentContainer)`
  & svg {
    margin: 1em 0;
  }

  & p {
    /* margin-bottom: 2em; */
  }
`;
