import React from 'react';
import { Section, Header, Slide, SlideChamada, Title, Excerpt, ButtonGroup, Button, SlideImage, Image, SeparateIcon } from './style';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Separate from '../../../assets/svg/AboutUs/separate-icon-service.svg';

const HighLevel = ({ bannerData }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Section id="banner" className="bottom">
      {bannerData.map((data, index) => (
        <div key={index}>
          <Header style={{ backgroundImage: `url(${data.thumbnailUrl})` }}></Header>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <Slide>
                  <SlideChamada>
                    <Title>{data.content}</Title>
                    <Excerpt>{data.excerpt}</Excerpt>
                    <ButtonGroup>
                      <Button onClick={() => navigate('/signup')}>{t('btn_signup_now')}</Button>
                      <Button onClick={() => navigate('/our_plans')}>{t('btn_view_all_plans')}</Button>
                    </ButtonGroup>
                  </SlideChamada>
                  <SlideImage>
                    <Image src={data.imgHeader} alt="Slide" />
                  </SlideImage>
                </Slide>
                <SeparateIcon src={Separate} alt="Separate Icon" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </Section>
    
  );
};

export default HighLevel;