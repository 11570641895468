import React from "react";
import { Menu, MenuHeader, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import theme from "../../shared/theme";
import { ReactComponent as SettingsIcon } from "../../assets/svg/settings-icon.svg";
import { useTranslation } from "react-i18next";
import { MenuButton } from "../../shared/style";
import { useAuth } from "../../contexts/AuthContext";

const SettingsMenu = () => {
  const { t } = useTranslation();
  const { handleLogout } = useAuth();

  const goToProfile = () => {
    console.log("Profile");
  };

  const optionStyle = () => {
    return {
      textAlign: "start",
      active: {
        backgroundColor: theme.colors.blue.default,
      },
    };
  };

  const renderButton = () => {
    return (
      <MenuButton style={{ marginLeft: "1em" }}>
        <SettingsIcon stroke={theme.colors.blue.default} />
      </MenuButton>
    );
  };

  return (
    <Menu menuButton={() => renderButton()} transition position="auto">
      <MenuHeader>{t("lbl_settings")}</MenuHeader>
      <MenuItem styles={optionStyle()} onClick={() => goToProfile()}>
        {t("lbl_profile")}
      </MenuItem>
      <MenuItem styles={optionStyle()} onClick={() => handleLogout()}>
        {t("lbl_logout")}
      </MenuItem>
    </Menu>
  );
};

export default SettingsMenu;
