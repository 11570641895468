import React from 'react';
import { Form } from "@unform/web";
import { useTranslation } from "react-i18next";
import { Grid, Row, Col } from "react-flexbox-grid";
import { RowWrapper, ContentContainer } from "../../shared/style";

import Panel from "../../components/Panel";
import Input from "../../components/Input";
import Button from "../../components/Button";
import ContactUsImage from "../../assets/svg/fale-conosco.svg"
import { ContactUsContainer, Textarea, TextareaRow, FormRow, ImageRow } from "./style";

const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <ContactUsContainer>
      <ContentContainer>
        <Grid>
          <RowWrapper middle="md">
            <Col lg={6}>
              <Row start="lg" center="xs">
                <Col xs={10} md={10} lg={10}>
                  <FormRow middle="md" center="xs" marginBottom="2em">
                    <Col>
                      <h3>{t("page_contact_lets_talk")}</h3>
                      <p>{t("page_contact_content")}</p>
                    </Col>
                  </FormRow>

                  <Panel>
                    <Grid>
                      <Form>
                        <FormRow>
                          <Col xs={12} md={12} lg={12}>
                            <Input
                              placeholder={t("ph_signup_name")}
                              label={t("lbl_full_name")}
                              name="name"
                            />
                          </Col>
                        </FormRow>

                        <FormRow>
                          <Col xs={12} md={12} lg={12}>
                            <Input
                              placeholder={t("ph_signup_email")}
                              label={t("lbl_email")}
                              name="email"
                            />
                          </Col>
                        </FormRow>

                        <TextareaRow>
                          <Col xs={12} md={12} lg={12}>
                            <label htmlFor="contact_message">{t("lbl_contact_message")}</label>
                            <Textarea
                              id="contact_message"
                              maxlength="500"
                              placeholder={t("ph_contact_message")}
                            />
                          </Col>
                        </TextareaRow>

                        <Row center="xs">
                          <Button
                            type="submit"
                            size="large"
                            label={t("lbl_send")}
                            disabled={false}
                          />
                        </Row>
                      </Form>
                    </Grid>
                  </Panel>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <ImageRow center="xs" end="lg">
                <img src={ContactUsImage} alt="Temporary" />
              </ImageRow>
            </Col>
          </RowWrapper>
        </Grid>
      </ContentContainer>
    </ContactUsContainer>
  );
}

export default ContactUs;