import { api } from "../lib/axios";

const paymentsUrl = "/plan/payment_preference";
const preferencesUrl = "/plan/plan_preference";

export const Plan = {
  payments(items) {
    return api.post(paymentsUrl, items);
  },

  preferences(items) {
    return api.post(preferencesUrl, items);
  },
};
