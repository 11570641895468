import { Col, Grid } from "react-flexbox-grid";
import styled from "styled-components";

export const DashboardContainer = styled(Grid)`
  padding-top: 2em;
  min-height: calc(100vh - 102px);
  overflow: auto;
`;

export const ColTable = styled(Col)`
  @media (max-width: 990px) {
    margin-top: 2em;
  }
`;
