import styled from 'styled-components';

export const CarouselSection = styled.div`
  position: relative;
  margin: -8rem 0;
  margin-bottom: 2rem;
  width: 50%;

  .carousel .control-arrow {
    background: transparent;
    border: none;
    box-shadow: none !important; /* Remove shadow */
    background-color: transparent !important;
    color: transparent !important;  
  }

  .carousel .control-arrow:before {
    border: solid #00B6BA;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 8px;
  }

  .carousel .control-next.control-arrow:before {
    transform: rotate(-45deg);
  }

  .carousel .control-prev.control-arrow:before {
    transform: rotate(135deg);
  }

  .carousel .control-arrow:hover:before,
  .carousel .control-arrow:focus:before,
  .carousel .control-arrow:active:before {
    border-color: #00B6BA; /* Ensure color stays the same on hover, focus, and active */
    box-shadow: none !important; /* Remove shadow on click */
    background-color: transparent !important;
    color: transparent !important;  
  }

  .carousel .control-dots .dot {
    background: #00B6BA;
    border-radius: 0; 
    width: 12px;
    height: 12px;
  }

  .carousel .control-dots .dot.selected {
    background: #F2B349;
  }
`;

export const CarouselItem = styled.div`
  background-color: #FFFFFF;
  padding: 2rem;
  border-radius: 10px;
  color: #05036F;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  margin: 3rem 0px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 10%;
`;

export const CarouselTitle = styled.h2`
  color: rgb(64, 64, 64);
  font-weight: 600;
  margin-right: 1rem;
`;

export const TitleImage = styled.img`
  width: 8px;
  height: 8px;
`;

export const CarouselContent = styled.div`
  color: rgb(64, 64, 64);
  font-size: 18px;
  line-height: 27px;
`;