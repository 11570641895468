import styled from "styled-components";
import { MapContainer as MapContainerLeaflet } from "react-leaflet";
import * as ScrollArea from "@radix-ui/react-scroll-area";

import theme from "../../shared/theme";

export const availableCountry = {
  weight: 0.5,
  fillColor: theme.colors.seaGreen.default,
  fillOpacity: 1,
};

export const restrictedCountry = {
  weight: 0.5,
  fillColor: theme.colors.yellow.default,
  fillOpacity: 1,
};

export const unavailableCountry = {
  weight: 0.5,
  fillColor: theme.colors.sweetRed.default,
  fillOpacity: 1,
};

export const defaultStyle = {
  weight: 0.5,
  fillColor: "transparent",
  fillOpacity: 1,
  color: "transparent",
};

export const MapWrapper = styled.div`
  display: flex;
  position: relative;

  @media (max-width: 768px) {
    margin-bottom: 1em;

    h1 {
      font-size: 16px;
    }
  }

  @media (max-width: 420px) {
    margin-bottom: 4em;
  }
`;

export const FloatingBox = styled.div`
  top: 2em;
  left: 1.5em;
  z-index: 4;
  max-width: 35%;

  @media (max-width: 420px) {
    max-width: 60%;
  }

  @media (max-width: 768px) {
    position: absolute;
  }

  @media (min-width: 769px) {
    position: absolute;
  }

  @media (min-width: 1439px) {
    max-width: 35%;
  }

  @media (min-width: 1919px) {
    max-width: 25%;
  }
`;

export const SearchResultContainer = styled.div`
  padding: 0;

  p {
    color: ${(props) => props.theme.colors.blue.default};
  }

  span {
    text-transform: capitalize;
    margin-left: 0.3em;
    font-size: ${(props) => props.theme.fontSizes.p2};
    color: ${(props) => props.theme.colors.black.default};
  }
`;

export const StatusColor = styled.div`
  border-radius: 50%;
  background-color: ${(props) => props.color};
  width: 1.125em;
  height: 1.125em;
`;

export const ScrollAreaRoot = styled(ScrollArea.Root)`
  width: 100%;
`;

export const ScrollAreaViewport = styled(ScrollArea.Viewport)`
  width: 100%;
  overflow-x: auto;
`;

export const ScrollAreaScrollbar = styled(ScrollArea.Scrollbar)`
  display: flex;
  height: 0.25em;
  touch-action: none;
  user-select: none;
  background-color: ${(props) => props.theme.colors.light.dark};
`;

export const ScrollAreaThumb = styled(ScrollArea.Thumb)`
  position: relative;
  flex: 1;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.black.light};
`;
