import { useCallback, useState } from "react";

import { Plan } from "../api/plan";

export const usePlan = () => {
  const [preference, setPreference] = useState({});

  const createPayment = useCallback(async (data) => {
    try {
      const response = await Plan.payments(data);

      return response.data;
    } catch (err) {
      console.log(err);
    }
  }, []);

  const createPreference = useCallback(async (data) => {
    try {
      const response = await Plan.preferences(data);

      if (response) {
        setPreference(response.data);
      }

      return response.data;
    } catch (err) {
      console.log(err);
    }
  }, []);

  return {
    preference,
    createPayment,
    createPreference,
  };
};
