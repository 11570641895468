import React from "react";
import Panel from "../Panel";
import { ReactComponent as BookIcon } from "../../assets/svg/book-icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/svg/magnifying-icon.svg";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import HistoryTable from "../HistoryTable";
import { Flex } from "../../shared/style";

const SearchHistory = ({ data = [] }) => {
  const { t } = useTranslation();
  const columns = [
    {
      Header: t("lbl_date"),
      accessor: "date",
    },
    {
      Header: t("lbl_search_strategy"),
      accessor: "strategy",
    },
    {
      Header: t("lbl_link"),
      accessor: "id",
    },
  ];

  function getLocaleDateString(dateValue) {
    let locale = localStorage.getItem("i18nextLng");

    if (locale === 'pt_BR') locale = 'pt'

    return dateValue.toLocaleDateString(locale) + ' - ' + dateValue.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' })
  }

  const dataFormatted = data.map((item) => {
    return {
      id: item.search_id,
      hash: item.query_hash,
      date: getLocaleDateString(new Date(item.search_date)),
      strategy: {
        boolean01: item.boolean01,
        boolean02: item.boolean02,
        keyword01: item.keyword01,
        keyword02: item.keyword02,
        keyword03: item.keyword03,
        query_hash: item.query_hash,
        search_id: item.search_id,
        tech_field: item.tech_field
      },
    }
  });

  return (
    <Panel
      icon={BookIcon}
      header={t("lbl_search_history")}
      action={
        <Button to="/search" icon={SearchIcon} label={t("lbl_new_search")} />
      }
      margin="0 0 2em 0"
    >
      <Flex alignItems="space-between">
        <HistoryTable columns={columns} data={dataFormatted} />
      </Flex>
    </Panel>
  );
};

export default SearchHistory;
