import axios from "axios";

export const baseURL = "https://app.platform321go.com/";

// axios.interceptors.response.use(response => {
//   return response;
// }, error => {
//   if (error.response.status === 401) {
//     //place your reentry code
//   }
//   return error;
// });

const customAxios = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

// customAxios.interceptors.request.use(function (config) {
//   // Do something before request is sent
//   console.log("Testando isso daqui")
//   console.log(config)
//   return config;
// }, function (error) {
//   // Do something with request error
//
//   return Promise.reject(error);
// });

customAxios.interceptors.response.use(response => {
  return response;
}, async (error) => {
  if (error.response.status === 401) {
    try{
      const { refresh_token } = JSON.parse(localStorage.getItem("token"))
      let config = {
        headers: {
          Authorization: `Bearer ${refresh_token}`,
        }
      }

      const { data: { access_token } } = await customAxios.post("/token/refresh", {}, config)

      console.log(access_token)

      const newLocalStorageToken = {
        token: access_token,
        refresh_token
      }

      localStorage.setItem("token", JSON.stringify(newLocalStorageToken))

      window.location.pathname = "/dashboard"
    } catch(error) {
      localStorage.removeItem("token")
      window.location.pathname = "/login"
    }

  }
  return error;
});
export const api321go = customAxios;
