import React, { useState } from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import Panel from "../Panel";
import { ReactComponent as DocIcon } from "../../assets/svg/doc-icon.svg";
import { ReactComponent as CheckIcon } from "../../assets/svg/check-icon.svg";
import { ReactComponent as AlertIcon } from "../../assets/svg/alert-icon.svg";
import { ReactComponent as DeniedIcon } from "../../assets/svg/denied-icon.svg";
import { ReactComponent as EmptyFileIcon } from "../../assets/svg/empty-file-icon.svg";
import Button from "../Button";
import Switch from "../Switch";
// import { useNavigate } from "react-router-dom";
import { ButtonsRow, TechnicalReportRow, TitleRow, ButtonContainer } from "./style";
import { useTranslation } from "react-i18next";
import { api321go } from "../../api/api321go";
// import { useAuth } from "../../contexts/AuthContext";

const TechnicalReportDownload = ({ handleNewSearch, searchKey, ...props }) => {
  const [mustShowAvailable, setMustShowAvailable] = useState(true);
  const [mustShowRestricted, setMustShowRestricted] = useState(true);
  const [mustShowDenied, setMustShowDenied] = useState(true);
  const { t } = useTranslation();
  // const { saveSearch } = useAuth();
  // const history = useNavigate();

  const handleChangeAvailable = (data) => {
    setMustShowAvailable(data);
    props.showAvailable(data);
  };

  const handleChangeRestricted = (data) => {
    setMustShowRestricted(data);
    props.showRestricted(data);
  };

  const handleChangeDenied = (data) => {
    setMustShowDenied(data);
    props.showDenied(data);
  };

  const download = async (kind, availability) => {
    try {
      const { data } = await api321go.get(
        `/search/${searchKey}/patents/${availability}/${kind}`,
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${searchKey} - ${kind} - ${availability}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } finally {
    }
  };

  const handlePatent = async (kind, availability) => {
    const downloadWindow = window.open(`/search/${searchKey}/download`, "_blank");
    await download(kind, availability);
    downloadWindow.close();
  };

  return (
    <Panel icon={DocIcon} header={t("lbl_diagnostic")}>
      <Grid style={{ padding: "0" }}>
        <Col lg={12}>
          <TechnicalReportRow middle="lg" between="lg">
            <Col xs={12} lg={6}>
              <TitleRow middle="lg">
                <div>
                  <CheckIcon />
                  <span>{t("lbl_available_contries")}</span>
                </div>
                <Switch
                  onChange={handleChangeAvailable}
                  checked={mustShowAvailable}
                />
              </TitleRow>
            </Col>
            <Col lg={5}>
              <Row middle="lg">
                <Col lg>
                  <Row style={{ paddingBottom: "0.5em" }}>
                    <Button
                      width="100%"
                      icon={EmptyFileIcon}
                      label={t("lbl_diagnostic")}
                      onClick={() => handlePatent("technical_report", "available")}
                    />
                  </Row>
                  <Row style={{ paddingBottom: "0.5em" }}>
                    <Button
                      width="100%"
                      icon={EmptyFileIcon}
                      label={t("lbl_patents")}
                      buttonStyle="outlined"
                      onClick={() => handlePatent("report", "available")}
                    />
                  </Row>
                </Col>
              </Row>
            </Col>
          </TechnicalReportRow>

          <TechnicalReportRow middleRow middle="lg" between="lg">
            <Col xs={12} lg={6}>
              <TitleRow middle="lg">
                <div>
                  <AlertIcon />
                  <span>{t("lbl_restricted_contries")}</span>
                </div>
                <Switch
                  onChange={handleChangeRestricted}
                  checked={mustShowRestricted}
                />
              </TitleRow>
            </Col>
            <Col lg={5}>
              <Row middle="lg">
                <Col lg>
                  <Row style={{ paddingBottom: "0.5em" }}>
                    <Button
                      width="100%"
                      icon={EmptyFileIcon}
                      label={t("lbl_diagnostic")}
                      onClick={async () => {
                        await download("technical_report", "restricted")
                      }}
                    />
                  </Row>
                  <Row>
                    <Button
                      width="100%"
                      icon={EmptyFileIcon}
                      label={t("lbl_patents")}
                      buttonStyle="outlined"
                      onClick={() => handlePatent("report", "restricted")}
                    />
                  </Row>
                </Col>
              </Row>
            </Col>
          </TechnicalReportRow>

          <TechnicalReportRow middle="lg" between="lg">
            <Col xs={12} lg={6}>
              <TitleRow middle="lg">
                <div>
                  <DeniedIcon />
                  <span>{t("lbl_unavailable_contries")}</span>
                </div>
                <Switch
                  onChange={handleChangeDenied}
                  checked={mustShowDenied}
                />
              </TitleRow>
            </Col>
            <Col lg={5}>
              <Row middle="lg">
                <Col lg>
                  <Row style={{ paddingBottom: "0.5em" }}>
                    <Button
                      width="100%"
                      icon={EmptyFileIcon}
                      label={t("lbl_diagnostic")}
                      onClick={async () => {
                        await download("technical_report", "unavailable")
                      }}
                    />
                  </Row>
                  <Row>
                    <Button
                      width="100%"
                      icon={EmptyFileIcon}
                      label={t("lbl_patents")}
                      buttonStyle="outlined"
                      onClick={() => handlePatent("report", "unavailable")}
                    />
                  </Row>
                </Col>
              </Row>
            </Col>
          </TechnicalReportRow>
        </Col>
      </Grid>
      <ButtonsRow>
        {/*<Flex width="50%" padding="0 0.75em 0 0">*/}
        {/*  <Button*/}
        {/*    onClick={saveResultAndGoToDashboard}*/}
        {/*    width="100%"*/}
        {/*    label={t("lbl_save_search")}*/}
        {/*    buttonStyle="outlined"*/}
        {/*  />*/}
        {/*</Flex>*/}
        <ButtonContainer>
          <Button
            onClick={handleNewSearch}
            label={t("lbl_new_search")}
            to={"/search"}
          />
        </ButtonContainer>
      </ButtonsRow>
    </Panel>
  );
};

export default TechnicalReportDownload;
