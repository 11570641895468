
import styled from 'styled-components';
export const BodyContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

export const Section = styled.div`
display: flex;
flex-direction: column;
`