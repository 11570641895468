import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { Flex, RowWrapper } from "../../shared/style";
import { BottomFooter, FooterContainer, SocialRow, TopFooter, FooterRow } from "./style";
import Logo321GO from "../../assets/svg/321GO-logo-footer.svg";
import LinkedIn from "../../assets/svg/linkedin-icon.svg";
import Youtube from "../../assets/svg/youtube-icon.svg";
import Instagram from "../../assets/svg/instagram-icon.svg";
import Copyright from "../../assets/svg/copyright-icon.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";

const Footer = () => {
  const { t } = useTranslation();
  const { authenticated } = useAuth();

  return (
    <FooterContainer fluid>
      <RowWrapper middle="md">
        {!authenticated && (
          <TopFooter md={12}>
            <FooterRow>
              <Col xs={12} sm={6} md={6} lg={6}>
                <Row start="md">
                  <img src={Logo321GO} alt="321Go Logo" />
                </Row>
              </Col>

              <Col xs={12} sm={6} md={6} lg={6}>
                <Row end="sm">
                  <Col>
                    <Flex flexDirection="column">
                      <p>{t("lbl_fast_access")}</p>
                      <Link to="/login">{t("lbl_login")}</Link>
                      <Link to="/contact_us">{t("lbl_contact_us")}</Link>
                      <Link to="/our_plans">{t("lbl_our_plans")}</Link>
                    </Flex>
                  </Col>
                </Row>
              </Col>
            </FooterRow>
          </TopFooter>
        )}
        <BottomFooter md={12}>
          <FooterRow middle="md">
            <Col xs={12} sm={6} md={6} lg={6}>
              <SocialRow>
                <img src={LinkedIn} alt="LinkedIn Logo" />
                <img src={Youtube} alt="Youtube Logo" />
                <img src={Instagram} alt="Instagram Logo" />
                <p>{t("lbl_follow_social")}</p>
              </SocialRow>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <Row end="sm">
                <img src={Copyright} alt="Copyright Logo" />
                <p>Copyright 2021 - 2024 | 321Go</p>
              </Row>
            </Col>
          </FooterRow>
        </BottomFooter>
      </RowWrapper>
    </FooterContainer>
  );
};

export default Footer;
