import React, { useRef, useState, useEffect } from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import ArrowGoBg from "../../assets/svg/arrow-go-bg.svg";
import Loader from "../../components/LoadScreen";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { api321go } from "../../api/api321go";
import ModalContent from "../../components/ModalContent";
import { ArrowLeft } from "react-feather";
import { Link } from "react-router-dom";
import { usePlan } from "../../hooks/usePlan";
import validateEnvironment from "../../shared/helpers/validateEnvironment";
import { initMercadoPago, Payment, StatusScreen } from "@mercadopago/sdk-react";
import {
  RowLine,
  BackBtn,
  PlanList,
  RowSelect,
  PricePlan,
  GridWrapper,
  GridContent,
  HeadContainer,
  DetailContainer,
  ToastRoot,
  ToastViewport,
} from "./style";
import { TicketForm } from "./TicketForm";
import { Select } from "./Select";
import * as Toast from '@radix-ui/react-toast';

var delayTimer;

const PlanDetail = () => {
  const publicKey = validateEnvironment({
    dev: process.env.REACT_APP_MERCADO_PAGO_PUBIC_KEY_DEV,
    prod: process.env.REACT_APP_MERCADO_PAGO_PUBIC_KEY_PROD,
  });

  initMercadoPago(`${publicKey}`, { locale: "pt-BR" });

  const { t } = useTranslation();
  const formRef = useRef(null);
  const timerRef = useRef(0);
  const planStorage = localStorage.getItem("planStorage");
  const planDetail = JSON.parse(planStorage);
  const [payment, setPayment] = useState(null);
  const [loadingTicket, setLoafingTicket] = useState(false);
  const [introModalIsOpen, setIntroModalIsOpen] = useState(true);
  const [userData, setUserData] = useState({
    name: "",
    type_person: "fisica",
    cnpj_cpf: "",
    address: "",
    num_address: "",
    neighborhood: "",
    city: "",
    state: "",
    zipcode: "",
    email: "",
    price: "2.50",
    plan_id: "1",
  });
  const [openPaymentStatus, setOpenPaymentStatus] = useState(false);
  const paymentMethods = [
    {
      label: "Boleto",
      value: 1,
    },
    {
      label: "Cartão de crédito",
      value: 2,
    },
  ];
  const [paymentMethod, setPaymentMethod] = useState(paymentMethods[0]);
  const { createPayment, createPreference, preference } = usePlan();
  const successUrl = validateEnvironment({
    dev: process.env.REACT_APP_MERCADO_PAGO_SUCCESS_URL_DEV,
    prod: process.env.REACT_APP_MERCADO_PAGO_SUCCESS_URL_PROD,
  });
  const failureUrl = validateEnvironment({
    dev: process.env.REACT_APP_MERCADO_PAGO_FAILURE_URL_DEV,
    prod: process.env.REACT_APP_MERCADO_PAGO_FAILURE_URL_PROD,
  });

  const initialization = preference && {
    amount: planDetail.unit_price,
    preferenceId: preference.id,
  };

  useEffect(() => {
    createPreference({
      id: planDetail.id,
      title: planDetail.name,
      unit_price: planDetail.unit_price,
      success_url: successUrl,
      failure_url: failureUrl,
    });
  }, []);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  const onPaymentStatus = () => {
    setOpenPaymentStatus(false);
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      setOpenPaymentStatus(true);
    }, 100);
  }

  const handleCloseIntroModal = () => {
    setIntroModalIsOpen(!introModalIsOpen);
  };

  const validateFieldsToSubmit = async (data) => {
    try {
      formRef.current.setErrors({});

      const signupSchema = yup.object().shape({
        name: yup.string().required(t("form_error_full_name_required")),
        cnpj_cpf: yup.string().required(t("lbl_CPF_required")),
        zipcode: yup.string().required(t("lbl_CEP_must_contain")),
        email: yup.string().email(t("form_error_email_invalid")).required(t("form_error_email_required")),
        // password: yup
        //     .string()
        //     .matches(/.*?[A-Z]/, t("form_error_password_invalid"))
        //     .matches(/.*?[a-z]/, t("form_error_password_invalid"))
        //     .matches(/.*?[0-9]/, t("form_error_password_invalid"))
        //     .matches(/.*?[#?!@$%^&*-]/, t("form_error_password_invalid"))
        //     .min(8, t("form_error_password_invalid"))
        //     .required(t("form_error_password_required")),
        // confirm_password: yup
        //     .string()
        //     .oneOf(
        //         [yup.ref("password"), null],
        //         t("form_error_password_not_match")
        //     ),
      });

      await signupSchema.validate(data, {
        abortEarly: false,
      });

      setLoafingTicket(true);
      try {
        const { data } = await api321go.post(
          `/plan/payment`,
          { ...userData, plan_id: "1", price: planDetail.price.replace(",", ".").replace("R$", "").trim() },
          { responseType: "blob" },
        );
        const url = window.URL.createObjectURL(new Blob([data], { type: "application/pdf" }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `boleto-321go.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoafingTicket(false);
      } catch (error) {
        console.log(error);
        setLoafingTicket(false);
      }
      // window.open(fileURL);

      // await handleSignup(data);
    } catch (error) {
      const validationErrors = {};
      if (error instanceof yup.ValidationError) {
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const handleChangePaymentMethod = (value) => {
    setPaymentMethod(value);
  };

  const onSubmitCardPayment = async ({ formData }) => {
    const response = await createPayment({ payment_data: formData });

    if (response) {
      setPayment(response);
      onPaymentStatus();
    }


    // if (response && (response.status === "approved" || response.status === "in_process")) {
    //   window.location.href = preference.back_urls.success;
    // }

    // window.location.href = preference.back_urls.failure;
  };

  return (
    <>
      <DetailContainer>
        <Loader active={loadingTicket} />
        <HeadContainer
          fluid
          style={{
            background: `#05036F url(${ArrowGoBg})`,
            backgroundClip: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Grid>
            <Row>
              <Col xs={12}>
                <Link to="/our_plans">
                  <BackBtn>
                    <ArrowLeft strokeWidth={3} size={20} />
                    {t("lbl_back")}
                  </BackBtn>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <PricePlan>
                  <h3>{t(planDetail.name)}</h3>
                  <h1>{planDetail.price}</h1>
                </PricePlan>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <RowLine />
              </Col>
            </Row>
          </Grid>
        </HeadContainer>

        <GridWrapper>
          <Row style={{ color: "#05036F", marginTop: "50px" }}>
            <Col xs={12}>
              <h2>{t("lbl_plan_details")}</h2>
            </Col>
          </Row>
          <GridContent>
            <PlanList xs={12} sm={12} md={12} lg={6}>
              <ul>
                {planDetail &&
                  planDetail.description &&
                  planDetail.description.map((item) => {
                    return <li key={item}>{t(item).replace("%", planDetail.price_test)}</li>;
                  })}
              </ul>
            </PlanList>

            <Col xs={12} sm={12} md={12} lg={6}>
              <RowSelect className="select">
                <Col xs={12}>
                  <Select
                    value={paymentMethod}
                    options={paymentMethods}
                    defaultValue={paymentMethod}
                    label={t("Método de pagamento")}
                    placeholder={t("Selecione o método de pagamento")}
                    onChange={(e) => handleChangePaymentMethod(e)}
                  />
                </Col>
              </RowSelect>

              {paymentMethod.value === 1 && (
                <TicketForm
                  formRef={formRef}
                  userData={userData}
                  delayTimer={delayTimer}
                  setUserData={setUserData}
                  validateFieldsToSubmit={validateFieldsToSubmit}
                />
              )}

              {paymentMethod.value === 2 && (
                <Payment
                  initialization={initialization}
                  customization={{
                    enableReviewStep: true,
                    paymentMethods: {
                      creditCard: "all",
                    },
                  }}
                  onSubmit={onSubmitCardPayment}
                />
              )}
            </Col>
          </GridContent>
        </GridWrapper>
        <ModalContent
          isOpen={introModalIsOpen}
          header="lbl_what_email_add_plan_title"
          content="lbl_what_email_add_plan_body"
          closeModal={handleCloseIntroModal}
        />
      </DetailContainer>

      <Toast.Provider duration={8000}>
        <ToastRoot open={openPaymentStatus} onOpenChange={setOpenPaymentStatus}>
          {payment && <StatusScreen initialization={{paymentId: payment.id}} />}
        </ToastRoot>
        <ToastViewport/>
      </Toast.Provider>
    </>
  );
};
export default PlanDetail;
