const replaceJSX = (fullString, stringToBeReplaced, jsxElement) => {
  const parts = fullString.split(stringToBeReplaced);
  const result = [];
  for (let i = 0; i < parts.length; i++) {
    result.push(parts[i]);
    if (i < parts.length - 1) result.push(jsxElement);
  }
  return result;
};

export default replaceJSX;
