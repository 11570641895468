import axios from "axios";

import validateEnvironment from "../shared/helpers/validateEnvironment";

const api = axios.create({
  baseURL: "https://api.platform321go.com/",
  timeout: 20000,
});

const err = (error) => {
  if (error && error.response && error.response.status) {
    switch (error.response.status) {
      case 401:
        // TODO
        break;

      default:
        return Promise.reject(error.response);
    }
  }
};

api.interceptors.request.use((config) => {
  const accessToken = validateEnvironment({
    dev: process.env.REACT_APP_MERCADO_PAGO_SECRET_KEY_DEV,
    prod: process.env.REACT_APP_MERCADO_PAGO_SECRET_KEY_PROD,
  });
  const token = `Bearer ${accessToken}`;
  config.headers.Authorization = token;
  if (config.headers["Content-Type"] === undefined) {
    config.headers["Content-Type"] = "application/json";
    config.headers.Accept = "application/json";
  }
  config.headers["cache-control"] = "no-cache";

  return config;
}, err);

export { api };
