import React, { useState } from "react";
import { Menu, MenuHeader, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import theme from "../../shared/theme";
import { ReactComponent as LanguageIcon } from "../../assets/svg/language-icon.svg";
import { useTranslation } from "react-i18next";
import { MenuButton } from "../../shared/style";
import i18next from "i18next";

const LanguageMenu = () => {
  const [language, setLanguage] = useState(i18next.language);
  const { t } = useTranslation();

  const changeLang = (lang) => {
    if (language !== lang) {
      i18next.changeLanguage(lang);
      setLanguage(lang);
    }
  };

  const optionStyle = (lang) => {
    return {
      textAlign: "start",
      backgroundColor: language === lang && theme.colors.blue.default,
      color: language === lang && theme.colors.light.pureWhite,
      active: {
        backgroundColor: theme.colors.blue.default,
      },
    };
  };

  const renderButton = () => {
    return (
      <MenuButton>
        {language}
        <LanguageIcon stroke={theme.colors.blue.default} />
      </MenuButton>
    );
  };

  return (
    <Menu menuButton={() => renderButton()} transition position="auto">
      <MenuHeader>{t("lbl_language")}</MenuHeader>
      <MenuItem
        styles={optionStyle("pt_BR")}
        onClick={() => changeLang("pt_BR")}
      >
        {t("lbl_portuguese")}
      </MenuItem>
      <MenuItem styles={optionStyle("en")} onClick={() => changeLang("en")}>
        {t("lbl_english")}
      </MenuItem>
      <MenuItem styles={optionStyle("es")} onClick={() => changeLang("es")}>
        {t("lbl_spanish")}
      </MenuItem>
    </Menu>
  );
};

export default LanguageMenu;
