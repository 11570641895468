import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  ZoomControl,
  GeoJSON,
  Tooltip,
} from "react-leaflet";
import Panel from "../../components/Panel";
import {
  availableCountry,
  defaultStyle,
  FloatingBox,
  MapWrapper,
  restrictedCountry,
  SearchResultContainer,
  StatusColor,
  unavailableCountry,
  ScrollAreaRoot,
  ScrollAreaViewport,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
} from "./style";
import "./style.css";
import { ReactComponent as ResultIcon } from "../../assets/svg/result-icon.svg";
import { useTranslation } from "react-i18next";
import { Flex } from "../../shared/style";
import TechnicalReport from "../../components/TechnicalReport";
import WorldData from "../../providers/geojson";
import theme from "../../shared/theme";
import { useSearch } from "../../contexts/SearchContext";

const ResultMap = () => {
  const { t } = useTranslation();
  const { searchTerms, setLoadingMap, countriesResult, setCountriesResult } = useSearch();
  const { keyword01, boolean01, keyword02, boolean02, keyword03, search_date, tech_field
  } = searchTerms;
  const [mustShowAvailable, setMustShowAvailable] = useState(true);
  const [mustShowRestricted, setMustShowRestricted] = useState(true);
  const [mustShowDenied, setMustShowDenied] = useState(true);


  useEffect(() => {
    setLoadingMap(false);
  });

  function getLocaleDateString(dateValue) {
    let locale = localStorage.getItem("i18nextLng");

    if (locale == 'pt_BR') locale = 'pt'

    return dateValue.toLocaleDateString(locale) + ' - ' + dateValue.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' })
  }

  const requestDate = () => {
    const now = searchTerms.time;
    const requestDate = `${now.getDate().toString().padStart(2, "0")}/${(
      now.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${now.getFullYear()}`;
    const requestHour = `${now.getHours().toString().padStart(2, "0")}:${now
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;

    return `${requestDate} - ${requestHour}`;
  };

  const getColorByStatus = (data) => {
    if (countriesResult && countriesResult !== null) {
      const country = data.properties.iso_a2;
      const isAvailable = countriesResult?.available.includes(country);
      const isRestricted = countriesResult?.restricted.includes(country);
      const isUnavailable = countriesResult?.unavailable.includes(country);

      if (isAvailable && mustShowAvailable) {
        return {
          status: t("lbl_available"),
          style: availableCountry,
          isToShow: mustShowAvailable,
          color: theme.colors.seaGreen.default,
        };
      } else if (isRestricted && mustShowRestricted) {
        return {
          status: t("lbl_restricted"),
          style: restrictedCountry,
          isToShow: mustShowRestricted,
          color: theme.colors.yellow.default,
        };
      } else if (isUnavailable && mustShowDenied) {
        return {
          status: t("lbl_unavailable"),
          style: unavailableCountry,
          isToShow: mustShowDenied,
          color: theme.colors.sweetRed.default,
        };
      } else {
        return {
          status: t("lbl_unavailable"),
          style: defaultStyle,
          isToShow: false,
        };
      }
    }
  };

  return (
    <>
      <ScrollAreaRoot>
        <ScrollAreaViewport>
          <MapWrapper>
            <FloatingBox>
              <Panel
                icon={ResultIcon}
                header={t("lbl_serach_results")}
                margin="0 0 1em 0"
              >
                <SearchResultContainer>
                  <Flex padding="0.5em 0" flexDirection="column" gap="1em">
                    <p>
                      {t("lbl_strategy")}:
                      <span>
                        {`${keyword01} ${t(`lbl_${boolean01.toLowerCase()}`)} ${keyword02.toLowerCase()} ${t(
                          `lbl_${boolean02.toLowerCase()}`
                        )} ${keyword03.toLowerCase()} ${t('lbl_and')} ${tech_field}`}
                      </span>
                    </p>
                    <p>
                      {t("lbl_date")}:
                      <span>{getLocaleDateString(new Date(search_date))}</span>
                    </p>
                  </Flex>
                </SearchResultContainer>
              </Panel>

              <TechnicalReport
                searchKey={searchTerms.searchKey}
                handleNewSearch={() => setCountriesResult(null)}
                showAvailable={setMustShowAvailable}
                showRestricted={setMustShowRestricted}
                showDenied={setMustShowDenied}
              />
            </FloatingBox>
            <MapContainer
              center={[20, -30]}
              zoom={2.2}
              scrollWheelZoom={false}
              zoomControl={false}
            >
              <TileLayer
                attribution='&copy; <a href="https://carto.com/">Carto</a>'
                url="http://a.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png"
              />
              <ZoomControl position="bottomright" />
              {WorldData.features.map((feature) => {
                const featureStatus = getColorByStatus(feature);
                if (featureStatus.isToShow) {
                  return (
                    <GeoJSON data={feature} style={featureStatus.style}>
                      <Tooltip opacity={1} sticky className="tooltip-container">
                        <Flex alignItems="center">
                          <StatusColor color={featureStatus.color} />

                          <Flex flexDirection="column" margin="0 0 0 1em">
                            <p>{feature.properties.name}</p>
                            <span>
                              {t("lbl_situation")}: {featureStatus.status}
                            </span>
                          </Flex>
                        </Flex>
                      </Tooltip>
                    </GeoJSON>
                  );
                }
              })}
            </MapContainer>
          </MapWrapper>
        </ScrollAreaViewport>

        <ScrollAreaScrollbar orientation="vertical">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </ScrollAreaRoot>
    </>
  );
};

export default ResultMap;
