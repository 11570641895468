import { Row } from "react-flexbox-grid";
import styled from "styled-components";

export const LoginRow = styled(Row)`
  margin-bottom: 1em;
`;

export const PasswordRow = styled(Row)`
  position: relative;
`;

export const TitleRow = styled(Row)`
  @media(max-width:768px) {
    h3 {
      font-size: ${(props) => props.theme.fontSizes.h2};
    }
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
  }
`