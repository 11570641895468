const theme = {
  colors: {
    blue: {
      light: "#482E9F",
      default: "#05036F",
      dark: "#000043",
    },
    seaGreen: {
      light: "#5EE9EC",
      default: "#00B6BA",
      dark: "#00868A",
    },
    yellow: {
      light: "#FFE579",
      default: "#F2B349",
      dark: "#BC8414",
    },
    sweetRed: {
      light: "#FF7C96",
      default: "#F14668",
      dark: "#B9003E",
    },
    light: {
      pureWhite: "#FFFFFF",
      default: "#F9F9F9",
      dark: "#C6C6C6",
    },
    black: {
      light: "#6B6B6B",
      default: "#404040",
      dark: "#1A1A1A",
    },
  },
  fontSizes: {
    h1: "1.5rem", //24px
    h2: "2.0625rem", //33px
    h3: "2.75rem", //44px
    p1: "1.125rem", //18px
    p2: "0.875rem", //14px
    caption: "0.625rem", //10px
  },
  fontWeights: {
    regular: "400",
    semiBold: "600",
  },
};

export default theme;
