import { createSlice } from '@reduxjs/toolkit'

export const planSlice = createSlice({
    name: 'plan',
    initialState: {
        value: {
            id: 1,
            name: "",
            price:"",
            description: ""
        },
    },
    reducers: {
        addPlan: (state, data) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.value = { ...state.value, ...data.payload}
        }
        ,
        clearPlan: (state) => {
            state.value = {
                id: 1,
                name: "",
                price:"",
                description: ""
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { addPlan, clearPlan } = planSlice.actions

export default planSlice.reducer