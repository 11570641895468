import styled from 'styled-components';

export const Section = styled.section`
  padding: 40px 0;
  text-align: center;
`;

export const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative; /* Adicione isto para que o botão posicionado absolutamente funcione */
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Col = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
`;

export const BlogImage = styled.img`
  width: 70%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
`;

export const BlogTitle = styled.h1`
  font-size: 2em;
  margin-bottom: 20px;
`;

export const BlogMeta = styled.div`
  font-size: 0.9em;
  color: #777;
  margin-bottom: 20px;
`;

export const BlogContent = styled.div`
  font-size: 1.1em;
  line-height: 1.8;
  margin-bottom: 40px;
  text-align: left;
  padding: 0 20px;
  
  p {
    margin-bottom: 16px;
  }
  
  ol {
    margin-bottom: 16px;
    padding-left: 20px;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const BackButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 20px;
  left: 20px;
  border: 1px solid #007bff;
  background-color: white;
  color: #007bff;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }

  &:active {
    background-color: #e0e0e0;
  }

  &:focus {
    outline: none;
  }
`;
