import React from "react";
import { default as ReactSwitch } from "react-switch";
import theme from "../../shared/theme";

const Switch = ({ onChange, checked, ...rest }) => {
  return (
    <ReactSwitch
      onChange={onChange}
      checked={checked}
      offColor={theme.colors.blue.default}
      onColor={theme.colors.seaGreen.default}
      height={18}
      width={32}
      uncheckedIcon={false}
      checkedIcon={false}
      {...rest}
    />
  );
};

export default Switch;
