import { Row, Col } from "react-flexbox-grid";
import styled from "styled-components";

import {
  getInputColors,
  getInputBoxShadow,
  getInputTextColors,
} from "../../utils/inputColors";

export const ContactUsContainer = styled.div`
  padding: 2em 0;
`;

export const FormRow = styled(Row)`
  margin-bottom: ${(props) => props.marginBottom || "1em"};

  @media(max-width:768px) {
    h3 {
      font-size: ${(props) => props.theme.fontSizes.h2};
    }
  }
`;

export const ImageRow = styled(Row)`
  padding-right: 0.5em;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const TextareaRow = styled(Row)`
  margin-bottom: ${(props) => props.marginBottom || "1em"};
  text-align: left;
  font-size: ${(props) => props.theme.fontSizes.p1};
`;

export const Textarea = styled.textarea`
  resize: none;
  width: 100%;
  padding: 0.5em;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.light.default};
  margin-top: 0.25em;
  height: 15.4375em;
  box-sizing: border-box;
  border: none;
  color: ${(props) => getInputTextColors(props)};
  box-shadow: inset 0 0 0 1px ${(props) => getInputColors(props)};

  transition: box-shadow 0.1s linear;

  &::placeholder {
    color: ${(props) => props.theme.colors.light.dark};
  }

  &:hover {
    box-shadow: inset 0 0 0 2px ${(props) => getInputColors(props)},
      ${(props) => getInputBoxShadow(props)};
  }

  &:focus {
    box-shadow: inset 0 0 0 1px ${(props) => getInputColors(props)},
      4px 4px 8px rgba(0, 0, 27, 0.1);
  }
`;
