import { Grid } from "react-flexbox-grid";
import styled from "styled-components";

export const ProfileGrid = styled(Grid)`
  padding: 0;
  margin-left: 0;
  margin-right: 0;

  & p {
    color: ${(props) => props.theme.colors.blue.default};
  }

  & span {
    overflow: hidden;
    /* word-break: break-word; */
    text-overflow: ellipsis;
    max-width: 80%;
    line-clamp: 1;

    margin-left: 1em;
    font-size: ${(props) => props.theme.fontSizes.p2};
  }
`;
