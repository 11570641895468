import { Link } from "react-router-dom";
import styled from "styled-components";

export const DefaultInTextLink = styled(Link)`
  font-size: ${(props) => props.fontSize};
  &:active,
  &:link,
  &:visited {
    color: ${(props) => props.theme.colors.blue.default};
    font-weight: ${(props) => props.fontWeight || "600"};
  }
`;
