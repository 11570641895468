import { LoadScreenBlock, LoadLoop, MessageContent } from "./style";
import LogoLoad from "../../assets/images/logo-load.gif"


const loadScreen = ({ message, active = false }) =>{
  return active ? (
    <LoadScreenBlock>
      <LoadLoop>
        <img src={LogoLoad} alt="" />
      </LoadLoop>
      {message && message.length > 0 && (
        <MessageContent>
        <h1>{message}</h1>
        </MessageContent>
      )}
    </LoadScreenBlock>
  ) : "";
};

export default loadScreen