import React from 'react';
import faperj from '../../../assets/svg/home/company/logo_faperj_branco_red.png';
import startupRio from '../../../assets/svg/home/company/logo-StartupRio.png';
import ifpb from '../../../assets/svg/home/company/logo-ifpb-red.png';
import { Section, Container, Row, Col, Title, Partners, PartnerImage } from './style';
import { useTranslation } from "react-i18next";
const Company = () => {
  const { t } = useTranslation();
  
  const partners = [
    { src: faperj, alt: 'Faperj' },
    { src: startupRio, alt: 'StartupRio' },
    { src: ifpb, alt: 'Ifpb' }
  ];

  return (
    <Section>
      <Container>
        <Row>
          <Col>
          <Title>{t("lbl_our_partners")}</Title>
            <Partners>
              {partners.map((partner, index) => (
                <PartnerImage key={index} src={partner.src} alt={partner.alt} />
              ))}
            </Partners>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default Company;
