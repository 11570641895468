import React from "react";
import { default as ReactModal } from "react-modal";

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 6,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
};

const Modal = (props) => {
  return (
    <ReactModal
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
      style={{...customStyles, ...props.style}}
    >
      {props.children}
    </ReactModal>
  );
};

export default Modal;
