import { Grid, Row, Col } from "react-flexbox-grid";
import Modal from "../../components/Modal"
import Button from "../Button";
import styled from "styled-components";
import { DisagreeBtn } from "./style"; 

const SignUpModal = ({ open, agreeBtn =  () => {}, disagreeBtn = () => {}, onClose = () => {} }) => {

  return (
    <>
      <Modal isOpen={open}>
        <Grid style={{ width: '90vw', height: '90vh', background: 'white', color: '#05036F', fontSize: '20px', padding: '10px',paddingLeft: '20px', borderRadius: '5px' }}>
          <Row style={{ justifyContent: 'space-between', alignItems: 'center', marginLeft: '20px', paddingRight: '40px', marginBottom: '10px', paddingTop: '20px' }}>
            <Row>
              Termo de Uso e Consentimento para Tratamento de Dados Pessoais
            </Row>
            <Row style={{cursor:'pointer'}} onClick={onClose}>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none" stroke="#05036F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </Row>
          </Row>
          <Row style={{ paddingLeft: '30px', paddingRight: '30px', justifyContent: 'start' }}>

            <Row style={{ width: '100%', justifyContent: 'center', background: '#05036F', height: '1px' }}> {/* Line */}
            </Row>

            <Col style={{ height: '67vh', overflow: 'scroll', padding: '10px' }}>
              <Row style={{ fontSize: '14px', color: 'black', marginTop: '20px' }}>
                Este documento objetiva registrar a manifestação livre, informada e inequívoca pela qual o Usuário/Cliente, Titular dos dados, concorda com o tratamento de seus dados pessoais para finalidade específica, em conformidade com a Lei nº 13.709 – Lei Geral de Proteção de Dados Pessoais (LGPD). Ao manifestar sua aceitação com o presente termo através do  aceite expresso no cadastro deste site, o Titular consente e concorda que a Plataforma 321Go doravante denominada Controladora, tome decisões referentes ao tratamento de seus dados pessoais, dados referentes as empresas em que atuem os usuários ou dados necessários ao usufruto de serviços ofertados pela Controladora, bem como realize o tratamento de tais dados, envolvendo operações como as que se referem à coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.
              </Row>

              <Row style={{ fontWeight: '500', marginTop: '30px' }}>
                Dados Pessoais
              </Row>
              <Row style={{ fontSize: '14px', color: 'black', marginTop: '10px' }}>
                A Controladora fica autorizada a realizar o tratamento dos dados pessoais do Titular abaixo listados e a tomar decisões referentes a esse tratamento:
              </Row>
              <Col style={{ flexWrap: 'nowrap', fontSize: '14px', color: 'black', marginTop: '10px' }}>
                <li>Nome completo ou Social;</li>
                <li>Nome empresarial;</li>
                <li>CPF ou CNPJ;</li>
                <li>Endereço completo;</li>
                <li>E-mail;</li>
                <li>Nome de usuário e senha específicos para uso dos serviços do Controlador;</li>
                <li>Comunicação, verbal e escrita, mantida entre o Titular e o Controlador.</li>
              </Col>

              <Row style={{ fontWeight: '500', marginTop: '30px' }}>
                Finalidades do Tratamento dos Dados
              </Row>
              <Col style={{ flexWrap: 'nowrap', fontSize: '14px', color: 'black', marginTop: '10px' }}>
                <li>O tratamento dos dados pessoais listados neste termo tem as seguintes finalidades:</li>
                <li>Possibilitar que a Controladora identifique e entre em contato com o Titular para fins de relacionamento comercial.</li>
                <li>Possibilitar que a Controladora elabore contratos comerciais e emita cobranças contra o Titular.</li>
                <li>Possibilitar que a Controladora envie ou forneça ao Titular seus produtos e serviços, de forma remunerada ou gratuita.</li>
                <li>Possibilitar que a Controladora estruture, teste, promova e faça propaganda de produtos e serviços, personalizados ou não ao perfil do Titular.</li>
                <li>Possibilitar que a Controladora utilize tais dados em Pesquisas de Mercado;</li>
                <li>Possibilitar que a Controladora utilize tais dados na inscrição, divulgação, premiação dos interessados participantes de Eventos, Prêmios ou Concursos;</li>
                <li>Possibilitar que a Controladora utilize tais dados na elaboração de catálogos e na Curadoria de autores; </li>
                <li>Possibilitar que a Controladora utilize tais dados na elaboração de relatórios e emissão de produtos e serviços;</li>
                <li>Possibilitar que a Controladora utilize tais dados para suas peças de Comunicação;</li>
                <li>Possibilitar que a Controladora utilize tais dados emissão de Notas Fiscais e documentos financeiros correlatos;</li>
                <li>Possibilitar que a Controladora utilize tais dados para facilitar a prestação de serviços diversos além dos primariamente contratados, desde que o cliente também demonstre interesse em contratar novos serviços;</li>
                <li>Possibilitar que a Controladora utilize tais dados para manter banco de dados de profissionais do mercado para facilitar o contato em futuros convites para eventos;</li>
              </Col>

              <Row style={{ fontWeight: '500', marginTop: '30px' }}>
                Compartilhamento de Dados
              </Row>
              <Row style={{ fontSize: '14px', color: 'black', marginTop: '10px' }}>
                A Controladora fica autorizada a compartilhar os dados pessoais do Titular com outros agentes de tratamento de dados, caso seja necessário para as finalidades listadas neste termo, observados os princípios e as garantias estabelecidas pela Lei nº 13.709.
              </Row>

              <Row style={{ fontWeight: '500', marginTop: '30px' }}>
                Segurança dos Dados
              </Row>
              <Row style={{ fontSize: '14px', color: 'black', marginTop: '10px' }}>
                A Controladora responsabiliza-se pela manutenção de medidas de segurança, técnica e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito. Em conformidade ao art. 48 da Lei nº 13.709, a Controladora comunicará ao Titular e à Autoridade Nacional de Proteção de Dados (ANPD) a ocorrência de incidente de segurança que possa acarretar risco ou dano relevante ao Titular.
              </Row>

              <Row style={{ fontWeight: '500', marginTop: '30px' }}>
                Término do Tratamento dos Dados
              </Row>
              <Row style={{ fontSize: '14px', color: 'black', marginTop: '10px' }}>
                A Controladora poderá manter e tratar os dados pessoais do Titular durante todo o período em que os mesmos forem pertinentes ao alcance das finalidades listadas neste termo. Dados pessoais anonimizados, sem possibilidade de associação ao indivíduo, poderão ser mantidos por período indefinido. O Titular poderá solicitar via e-mail ou correspondência a Controladora, a qualquer momento, que sejam eliminados os dados pessoais não anonimizados do Titular. O Titular fica ciente de que poderá ser inviável a Controladora continuar o fornecimento de produtos ou serviços ao Titular a partir da eliminação dos dados pessoais.
              </Row>

              <Row style={{ fontWeight: '500', marginTop: '30px' }}>
                Direitos do Titular
              </Row>
              <Row style={{ fontSize: '14px', color: 'black', marginTop: '10px' }}>
                O Titular tem direito a obter da Controladora, em relação aos dados por ele tratados, a qualquer momento e mediante requisição: I - confirmação da existência de tratamento; II - acesso aos dados; III - correção de dados incompletos, inexatos ou desatualizados; IV - anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na Lei nº 13.709; V - portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa e observados os segredos comercial e industrial, de acordo com a regulamentação do órgão controlador; V - portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial; VI - eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas no art. 16 da Lei nº 13.709; VII - informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados; VIII - informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa; IX - revogação do consentimento, nos termos do § 5º do art. 8º da Lei nº 13.709.
              </Row>

              <Row style={{ fontWeight: '500', marginTop: '30px' }}>
                Direito de Revogação do Consentimento
              </Row>
              <Row style={{ fontSize: '14px', color: 'black', marginTop: '10px' }}>
                Este consentimento poderá ser revogado pelo Titular, a qualquer momento, mediante solicitação via e-mail ou correspondência a Controladora. Para entrar em contato com a Controladora para todas solicitações acima, acessar o Fale Conosco no menu superior deste website.
              </Row>

            </Col>
          </Row>
          <Row style={{ background: '#05036F', height: '1px', marginLeft: '20px', marginRight: '20px' }}> {/* Line */}
          </Row>
          <Row style={{ justifyContent: 'center', marginTop: '10px', alignItems: 'center', gap: '20px' }}>
            <Button label={'Concordo'} onClick={agreeBtn} />
            <DisagreeBtn label={'Discordo'} onClick={disagreeBtn}> Discordo</DisagreeBtn>
          </Row>

        </Grid>
      </Modal>
    </>
  )

}
export default SignUpModal