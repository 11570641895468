import styled from "styled-components";

export const DefaultIconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  height: 25px;
  cursor: pointer;

  &:disabled {
    & svg {
      stroke: ${(props) => props.theme.colors.light.dark};
      cursor: default;
    }
  }
`;
