import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { api321go } from "../api/api321go";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { show, hide } from '../loaderSlice'
import { createContext, useContextSelector } from "use-context-selector";
const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingSearchHistory, setLoadingSearchHistory] = useState(false);
  let redirectAuthPath = location.state?.path || "/dashboard";
  const [user, setUser] = useState({
    "id": 0,
    "user_name": "",
    "email": "",
    "occupation": {
      "en": "",
      "pt": "",
      "es": ""
    },
    "organization_type": {
      "en": "",
      "pt": "",
      "es": ""
    }
  });

  useEffect(() => {
    redirectAuthPath = location.pathname;
    let storedToken = localStorage.getItem("token");


    if (!!storedToken && storedToken != 'undefined') {
      storedToken = JSON.parse(storedToken);

      if (storedToken.hasOwnProperty("access_token")) {
        api321go.defaults.headers.Authorization = `Bearer ${storedToken.access_token}`;
        setAuthenticated(true);
        goToDashboard();
      }
    }
  }, []);

  const goToDashboard = () => {
    navigate("/dashboard");
  };

  const handleLogin = async (loginData) => {
    try {
      setLoadingLogin(true);
      const { data } = await api321go.post("/auth/login", loginData);
      localStorage.setItem("token", JSON.stringify(data));
      api321go.defaults.headers.Authorization = `Bearer ${data.access_token}`;
      setAuthenticated(true);
      goToDashboard();
    } finally {
      setLoadingLogin(false);
    }
  };

  const handleSignUp = (data, clbk) => {

    dispatch(show())
    api321go.post("/auth/signup", data).then((response) => {
      const data = { ...response }
      if (data.hasOwnProperty('response') && data.response.status == 400) {
        dispatch(hide())
        clbk(400)
      } else {
        dispatch(hide())
        clbk(200)
      }
    }).catch((error) => {
      console.log(error)
      clbk(500)
      dispatch(hide())
    });

  };


  const handleForgotPassword = async (email) => {
    try {
      await api321go.post("/auth/forgot", { email });
    } catch (error) {
      console.log(error)
    }
  }

  const handleResetPassword = async (password, reset_token) => {
    try {
      await api321go.post("/auth/reset", {
        password,
        reset_token
      });
    } catch (error) {
      console.log(error)
    }
  }


  const handleSearchHistory = async () => {
    try {
      setLoadingSearchHistory(true);
      const { access_token } = JSON.parse(localStorage.getItem("token"));
      api321go.defaults.headers.Authorization = `Bearer ${access_token}`;

      const { data } = await api321go.get("/retrieve/searches_history");
      return data;
    } finally {
      setLoadingSearchHistory(false);
    }
  }


  const handleUserData = async () => {
    try {
      setLoadingUser(true);
      const { access_token } = JSON.parse(localStorage.getItem("token"));
      api321go.defaults.headers.Authorization = `Bearer ${access_token}`;

      const { data } = await api321go.get("/home");

      setUser(data);

      return data;

    } finally {
      setLoadingUser(false);
    }
  }

  const saveSearch = async (searchKey) => {
    try {
      dispatch(show());
      const { access_token } = JSON.parse(localStorage.getItem("token"));
      api321go.defaults.headers.Authorization = `Bearer ${access_token}`;

      const { data } = await api321go.post(`/save_search/${searchKey}`);

      goToDashboard();
      dispatch(hide());
      // return data;
    } catch (error) {
      console.log(error);
      dispatch(hide());
    }
  }
  const handleLogout = () => {
    setAuthenticated(false);
    localStorage.removeItem("token");
    api321go.defaults.headers.Authorization = undefined;
    navigate("/login", { replace: true });
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        handleLogin,
        handleLogout,
        handleSearchHistory,
        saveSearch,
        handleUserData,
        handleForgotPassword,
        handleResetPassword,
        handleSignUp,
        authenticated,

        loadingUser,
        loadingLogin,
        loadingSearchHistory,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};



export const useAuth = () => {
  const user = useContextSelector(AuthContext, (auth) => auth.user);
  const saveSearch = useContextSelector(AuthContext, (auth) => auth.saveSearch);
  const handleLogin = useContextSelector(AuthContext, (auth) => auth.handleLogin);
  const loadingUser = useContextSelector(AuthContext, (auth) => auth.loadingUser);
  const handleLogout = useContextSelector(AuthContext, (auth) => auth.handleLogout);
  const handleSignUp = useContextSelector(AuthContext, (auth) => auth.handleSignUp);
  const loadingLogin = useContextSelector(AuthContext, (auth) => auth.loadingLogin);
  const authenticated = useContextSelector(AuthContext, (auth) => auth.authenticated);
  const handleUserData = useContextSelector(AuthContext, (auth) => auth.handleUserData);
  const handleResetPassword = useContextSelector(AuthContext, (auth) => auth.handleResetPassword);
  const handleSearchHistory = useContextSelector(AuthContext, (auth) => auth.handleSearchHistory);
  const handleForgotPassword = useContextSelector(AuthContext, (auth) => auth.handleForgotPassword);
  const loadingSearchHistory = useContextSelector(AuthContext, (auth) => auth.loadingSearchHistory);

  return {
    user,
    saveSearch,
    handleLogin,
    handleLogout,
    handleSignUp,
    authenticated,
    handleUserData,
    handleSearchHistory,
    handleForgotPassword,
    handleResetPassword,

    loadingUser,
    loadingLogin,
    loadingSearchHistory,
  };
};
