import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { CarouselSection, CarouselTitle, CarouselContent, CarouselItem, TitleContainer, TitleImage } from './style';
import Separate from '../../../assets/svg/AboutUs/separate-icon-service.svg';
import { useTranslation } from "react-i18next";
const CarouselComponent = () => {
  const { t } = useTranslation();
  const carouselData = [
    {
      title: t("mission_title"),
      content: `
        <p>${t("mission_content")}</p>
      `
    },
    {
      title: t("vision_title"),
      content: `
        <p>${t("vision_content")}</p>
      `
    }
  ];

  return (
    <CarouselSection>
      <Carousel 
        showThumbs={false} 
        autoPlay 
        interval={7000} 
        infiniteLoop 
        showStatus={false}
      >
        {carouselData.map((data, index) => (
          <CarouselItem key={index}>
            <TitleContainer>
              <CarouselTitle>{data.title}</CarouselTitle>
              <TitleImage src={Separate} alt="Separate Icon" />
            </TitleContainer>
            <CarouselContent dangerouslySetInnerHTML={{ __html: data.content }} />
          </CarouselItem>
        ))}
      </Carousel>
    </CarouselSection>
  );
};

export default CarouselComponent;