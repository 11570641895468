import React, { useState, useRef, useEffect } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import { useTranslation } from "react-i18next";
import Panel from "../../components/Panel";
import { RowWrapper, ContentContainer } from "../../shared/style";
import { Form } from "@unform/web";
import Input from "../../components/Input";
import Select from "../../components/Select";
import { SignupContainer, FormRow, PasswordCol, ImageRow, TitleRow } from "./style";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import replaceJSX from "../../utils/translateWithJSX";
import InTextLink from "../../components/InTextLink";
import Feedback from "../../components/Feedback";
import * as yup from "yup";
import { api321go } from "../../api/api321go";
import i18n from "i18next";
import { useAuth } from "../../contexts/AuthContext";
import SignUpModal from "../../components/SignUpModal";
import styled from "styled-components";
import ShowPasswordButton from "../../components/ShowPasswordButton";
import TemplateImage from "../../assets/svg/temp-image.svg";
import ModalContent from "../../components/ModalContent";

const AcceptTermsStyle = styled.button`
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.theme.colors.blue.default};
  font-weight: ${(props) => props.fontWeight || "600"};
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`;

const Signup = () => {
  const { t } = useTranslation();
  const { handleSignUp } = useAuth();
  const [feedback, setFeedback] = useState(null);
  const formRef = useRef(null);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [occupations, setOccupations] = useState([]);
  const [organizationTypes, setOrganizationTypes] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [introModalIsOpen, setIntroModalIsOpen] = useState(true);

  const handleCloseIntroModal = () => {
    setIntroModalIsOpen(!introModalIsOpen);
  };

  const handleSignup = async (data) => {
    try {
      const { name, occupation, organization, email, password } = data;
      const body = {
        user_name: name,
        occupation_id: occupation,
        organization_type_id: organization,
        email,
        password,
      };

      handleSignUp(body, (statusCode) => {
        if (statusCode == 400) {
          formRef.current.setErrors({
            email: t("form_error_email_exists"),
          });
        } else if (statusCode == 200) {
          formRef.current.setErrors({});
          setFeedback({
            title: t("fb_success_signup_title"),
            explain: t("fb_success_signup_body"),
            success: true,
          });
        } else {
          setFeedback({
            title: t("fb_failure_general_title"),
            explain: t("fb_failure_general_body"),
            success: false,
            feedbackAction: {
              action: () => setFeedback(null),
              label: t("lbl_back"),
            },
          });
        }
      });
    } catch (error) {
      setFeedback({
        title: t("fb_failure_general_title"),
        explain: t("fb_failure_general_body"),
        success: false,
        feedbackAction: {
          action: () => setFeedback(null),
          label: t("lbl_back"),
        },
      });
    }
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const validateFieldsToSubmit = async (data) => {
    try {
      formRef.current.setErrors({});

      const signupSchema = yup.object().shape({
        name: yup.string().required(t("form_error_full_name_required")),
        occupation: yup.string().required(t("form_error_job_required")),
        organization: yup.string().required(t("form_error_organization_required")),
        email: yup.string().email(t("form_error_email_invalid")).required(t("form_error_email_required")),
        password: yup
          .string()
          .matches(/.*?[A-Z]/, t("form_error_password_invalid"))
          .matches(/.*?[a-z]/, t("form_error_password_invalid"))
          .matches(/.*?[0-9]/, t("form_error_password_invalid"))
          .matches(/.*?[#?!@$%^&*-]/, t("form_error_password_invalid"))
          .min(8, t("form_error_password_invalid"))
          .required(t("form_error_password_required")),
        confirm_password: yup
          .string()
          .oneOf([yup.ref("password"), null], t("form_error_password_not_match")),
      });

      await signupSchema.validate(data, {
        abortEarly: false,
      });

      delete data.confirm_password;

      await handleSignup(data);
    } catch (error) {
      const validationErrors = {};
      if (error instanceof yup.ValidationError) {
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const loadOccupations = async () => {
    const { data } = await api321go.get("/occupations");
    let locale = localStorage.getItem("i18nextLng");
    if (locale == "pt_BR") locale = "pt";

    let oc = data.map((item) => {
      return { label: item[`occupation_${locale}`], value: item.id };
    });

    setOccupations(oc);
  };

  const loadOrganizationTypes = async () => {
    const { data } = await api321go.get("/org_types");
    let locale = localStorage.getItem("i18nextLng");
    if (locale == "pt_BR") locale = "pt";

    let ot = data.map((item) => {
      return { label: item[`org_type_${locale}`], value: item.id };
    });

    setOrganizationTypes(ot);
  };

  useEffect(() => {
    loadOrganizationTypes();
    loadOccupations();
  }, []);

  let functions = [
    { label: t("student"), value: "student" },
    { label: t("researcher"), value: "researcher" },
    { label: t("teacher"), value: "teacher" },
    { label: t("lawyer"), value: "lawyer" },
    { label: t("manager"), value: "manager" },
    { label: t("production_manager"), value: "production_manager" },
    { label: t("risk_investor"), value: "risk_investor" },
    { label: t("pi_expert"), value: "pi_expert" },
    { label: t("ceo"), value: "ceo" },
    { label: t("others"), value: "others" },
  ];

  functions = functions.sort((a, b) => a.label.localeCompare(b.label));

  let organization = [
    { label: t("academy"), value: "academy" },
    { label: t("law_office"), value: "law_office" },
    { label: t("industry"), value: "industry" },
    { label: t("investment"), value: "investment" },
    { label: t("government"), value: "government" },
    { label: t("company_startup"), value: "company_startup" },
    { label: t("others"), value: "others" },
  ];

  organization = organization.sort((a, b) => a.label.localeCompare(b.label));

  if (feedback) {
    return <Feedback feedback={feedback} />;
  }

  const agreeBtn = () => {
    setOpenModal(false);
    setAcceptTerms(true);
  };

  const disagreeBtn = () => {
    setOpenModal(false);
    setAcceptTerms(false);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <SignupContainer>
      <ContentContainer>
        <Grid>
          <RowWrapper middle="md">
            <Col lg={8}>
              <Row start="lg" center="xs">
                <SignUpModal
                  open={openModal}
                  agreeBtn={agreeBtn}
                  disagreeBtn={disagreeBtn}
                  onClose={onCloseModal}
                />
                <Col xs={10} md={10} lg={10}>
                  <FormRow middle="md" center="xs" marginBottom="2em">
                    <Col>
                      <TitleRow>
                        <h3>{t("page_signup_signup_here")}</h3>
                      </TitleRow>
                      <p>
                        {replaceJSX(
                          t("page_signup_make_login"),
                          "%",
                          <InTextLink to="/login" label={t("lbl_login")} />,
                        )}
                      </p>
                    </Col>
                  </FormRow>

                  <Panel>
                    <Grid>
                      <FormRow middle="md" start="lg" marginBottom="2em">
                        <Col xs={12} md={12} lg={12}>
                          <h2>{t("lbl_registration")}</h2>
                        </Col>
                      </FormRow>

                      <FormRow middle="md" start="lg">
                        <Col xs={12} md={12} lg={12}>
                          <h1>{t("page_signup_personal_info")}</h1>
                        </Col>
                      </FormRow>

                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <Form ref={formRef} onSubmit={validateFieldsToSubmit}>
                            <FormRow>
                              <Col xs={12} md={12} lg={12}>
                                <Input
                                  placeholder={t("ph_signup_name")}
                                  label={t("lbl_full_name")}
                                  name="name"
                                />
                              </Col>
                            </FormRow>

                            <FormRow>
                              <Col xs={12} md={12} lg={6} className="select">
                                <Select
                                  placeholder={t("ph_signup_job")}
                                  label={t("lbl_function")}
                                  name="occupation"
                                  options={occupations}
                                />
                              </Col>

                              <Col xs={12} md={12} lg={6} className="select">
                                <Select
                                  placeholder={t("ph_signup_organization")}
                                  label={t("lbl_organization_type")}
                                  name="organization"
                                  options={organizationTypes}
                                />
                              </Col>
                            </FormRow>

                            <FormRow middle="md" start="lg">
                              <Col xs={12} md={12} lg={12}>
                                <h1>{t("lbl_account")}</h1>
                              </Col>
                            </FormRow>

                            <FormRow>
                              <Col xs={12} md={12} lg={12}>
                                <Input
                                  placeholder={t("ph_signup_email")}
                                  label={t("lbl_email")}
                                  name="email"
                                />
                              </Col>
                            </FormRow>

                            <FormRow>
                              <PasswordCol xs={12} md={12} lg={12}>
                                <Input
                                  placeholder={t("ph_password")}
                                  label={t("lbl_create_password")}
                                  name="password"
                                  type={passwordShown ? "text " : "password"}
                                  tip={t("password_tooltip")}
                                />
                                <ShowPasswordButton
                                  passwordShown={passwordShown}
                                  onTogglePassword={togglePassword}
                                />
                              </PasswordCol>
                            </FormRow>

                            <FormRow>
                              <PasswordCol xs={12} md={12} lg={12}>
                                <Input
                                  placeholder={t("ph_confirm_password")}
                                  label={t("lbl_confirm_password")}
                                  name="confirm_password"
                                  type={confirmPasswordShown ? "text" : "password"}
                                />
                                <ShowPasswordButton
                                  passwordShown={confirmPasswordShown}
                                  onTogglePassword={toggleConfirmPassword}
                                />
                              </PasswordCol>
                            </FormRow>

                            <FormRow center="xs">
                              <Col>
                                <Checkbox
                                  label={replaceJSX(t("use_term_confirm"), "%")}
                                  afterCheckLabel={
                                    <>
                                      <AcceptTermsStyle
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setOpenModal(true);
                                        }}
                                      >
                                        {" "}
                                        {t("lbl_use_term")}
                                      </AcceptTermsStyle>
                                      .
                                    </>
                                  }
                                  checked={acceptTerms}
                                  onChange={() => setAcceptTerms(!acceptTerms)}
                                />
                              </Col>
                            </FormRow>

                            <Row center="xs">
                              <Button
                                type="submit"
                                size="large"
                                label={t("lbl_register")}
                                disabled={!acceptTerms}
                              />
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Grid>
                  </Panel>
                </Col>
              </Row>
            </Col>
            <Col lg={4}>
              <ImageRow center="xs" end="lg">
                <img src={TemplateImage} alt="Temporary" />
              </ImageRow>
            </Col>
          </RowWrapper>
        </Grid>
      </ContentContainer>
      <ModalContent
        isOpen={introModalIsOpen}
        header="lbl_what_email_add_signup_title"
        content="lbl_what_email_add_signup_body"
        closeModal={handleCloseIntroModal}
      />
    </SignupContainer>
  );
};

export default Signup;
