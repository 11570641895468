import { ENVIRONMENTS } from "../../config/constants/environments";

function validateEnvironment({ prod, dev }) {
  if (process.env.REACT_APP_PROJECT_ENV === ENVIRONMENTS.PRODUCTION) {
    return prod;
  }

  if (process.env.REACT_APP_PROJECT_ENV === ENVIRONMENTS.DEV) {
    return dev;
  }
}

export default validateEnvironment;
