import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ScrollToTop from "../components/ScrollToTop";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import ForgotPassword from "../pages/ForgotPassword";
import { Flex } from "../shared/style";
import NewPassword from "../pages/NewPassword";
import Dashboard from "../pages/Dashboard";
import Search from "../pages/Search";
import OurPlans from "../pages/OurPlans";
import RequireAuth from "./RequiredAuth";
import { AuthProvider } from "../contexts/AuthContext";
import { SearchProvider } from "../contexts/SearchContext";
import PlanDetail from "../pages/PlanDetail";
import ErrPage from "../pages/ErrPage";
import Download from "../pages/Download";
import ResultMap from "../pages/ResultMap";
import ContactUs from "../pages/ContactUs";
import Support from "../pages/Support";
import Blog from "../pages/Blog";
import BlogPost from "../components/Blog/BlogPost";
import AboutUs from "../pages/AboutUs";


const TempNotFound = () => {
  return (
    <Flex style={{ height: "calc(100vh - 64px)" }}>
      <p>Temporary Not Found Page Component</p>
    </Flex>
  );
};

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <SearchProvider>
          <ScrollToTop />
          <Header />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route
              path="about-us"
              element={
                <AboutUs />
              }
            />
            <Route path="signup" element={<Signup />} />
            <Route path="login" element={<Login />} />
            <Route path="forgot_password" element={<ForgotPassword />} />
            <Route path="new_password/:token" element={<NewPassword />} />
            <Route
              path="dashboard"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />

            <Route
              path="search"
              element={
                <RequireAuth>
                  <Search />
                </RequireAuth>
              }
            />

            <Route
              path="search/:token"
              element={
                <RequireAuth>
                  <ResultMap />
                </RequireAuth>
              }
            />

            <Route
              path="subscription"
              element={
                <RequireAuth>
                  <OurPlans />
                </RequireAuth>
              }
            />

            <Route
              path="support"
              element={
                <RequireAuth>
                  <Support />
                </RequireAuth>
              }
            />

            <Route
              path="search/:token/download"
              element={
                // <RequireAuth>
                <Download />
                // </RequireAuth>
              }
            />

            <Route
              path="our_plans"
              element={
                <OurPlans />
              }
            />
          
              
            <Route
              path="blog"
              element={
                <Blog />
              }
            /> 

            <Route 
              path="/blog/:postId" 
              element=
              {<BlogPost />} 
            />


            <Route
              path="plan_detail"
              element={
                <PlanDetail />
              }
            />

            <Route
              path="contact_us"
              element={
                <ContactUs />
              }
            />

            <Route
              path="err_page"
              element={
                <ErrPage />
              }
            />

            <Route path="*" element={<TempNotFound />} />
          </Routes>
          <Footer />
        </SearchProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default AppRoutes;
