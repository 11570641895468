import styled from "styled-components";


export const BoxDots = styled.div`
  display: flex;
  gap: 6px
`;

export const Dot1 = styled.div`
  width: 10px;
  height: 10px;
  background-color: #05036F;
  border-radius: 10px;
`;

export const Dot2 = styled.div`
  width: 10px;
  height: 10px;
  background-color: #00B6BA;
  border-radius: 10px;
`;

export const Dot3 = styled.div`
  width: 10px;
  height: 10px;
  background-color: #F2B349;
  border-radius: 10px;
`;

export const Dot4  = styled.div`
  width: 10px;
  height: 10px;
  background-color: #F14668;
  border-radius: 10px;
`;


