import { useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";

const SearchContext = createContext({});

export const SearchProvider = ({ children }) => {
  const [countriesResult, setCountriesResult] = useState(null);
  const [searchTerms, setSearchTerms] = useState(null);
  const [loadingMap, setLoadingMap] = useState(true);

  return (
    <SearchContext.Provider value={{
      loadingMap,
      searchTerms,
      setLoadingMap,
      setSearchTerms,
      countriesResult,
      setCountriesResult,
    }}>
      {children}
    </SearchContext.Provider>
  )
};

export function useSearch() {
  const searchTerms = useContextSelector(
    SearchContext, (action) => action.searchTerms
  );
  const setSearchTerms = useContextSelector(
    SearchContext, (action) => action.setSearchTerms
  );
  const countriesResult = useContextSelector(
    SearchContext, (action) => action.countriesResult
  );
  const setCountriesResult = useContextSelector(
    SearchContext, (action) => action.setCountriesResult
  );
  const loadingMap = useContextSelector(
    SearchContext, (action) => action.loadingMap
  );
  const setLoadingMap = useContextSelector(
    SearchContext, (action) => action.setLoadingMap
  );

  return {
    loadingMap,
    searchTerms,
    setLoadingMap,
    setSearchTerms,
    countriesResult,
    setCountriesResult,
  };
}