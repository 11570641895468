import { Row } from "react-flexbox-grid";
import styled from "styled-components";
import { Flex } from "../../shared/style";

export const ButtonsRow = styled(Flex)`
  border-top: 1px solid ${(props) => props.theme.colors.blue.default};
  padding: 1em 0 0 0;
  align-items: center;
  justify-content: flex-end;
`;

export const TechnicalReportRow = styled(Row)`
  padding: 1em 0;
  align-items: center;
  justify-content: space-between;

  border-top: ${(props) =>
    props.middleRow && `1px solid ${props.theme.colors.light.dark}`};

  border-bottom: ${(props) =>
    props.middleRow && `1px solid ${props.theme.colors.light.dark}`};

  & svg {
    width: 24px;
    height: 24px;
    line-height: 0;
  }

  & span {
    font-size: ${(props) => props.theme.fontSizes.p2};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.blue.default};
    word-break: break-word;
  }

  @media (max-width: 768px) {
    padding: 1em 0;
    gap: 0.5em;
  }

  @media (max-width: 420px) {
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const TitleRow = styled(Row)`
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
    gap: 0.3em;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    width: 41.7%;
  }

  @media (max-width: 768px) {
    button {
      width: 100%;
    }
  }
`;
