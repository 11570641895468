import HighLevel from "../../components/AboutUs/high-level"
import comLupa from '../../assets/svg/home/high-level/Menino-com-Lupa.png'
import MidLevel from "../../components/AboutUs/mid-level"
import { BodyContainer,  Section } from "./style";
import { useTranslation } from "react-i18next";
export default function AboutUs(){
  const { t } = useTranslation();
  
    const bannerData = [
        {
          thumbnailUrl: 'path/to/image1.jpg',
          content: t("lbl_who_we_are"),
          excerpt: 'Somos duas mulheres com carreiras consolidadas, atuando nas áreas de Propriedade Intelectual e Gestão de Projetos, tanto em empresas privadas como instituições públicas.',
          btnCadastre: '#',
          btnPlanos: '#',
          imgHeader: comLupa
        },
      ];
    return(
      <Section>
           <HighLevel bannerData={bannerData}/>
        <BodyContainer>
            <MidLevel />
        </BodyContainer>
      </Section>
      
    )
}