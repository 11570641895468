import React, { useEffect } from "react";
import { ReactComponent as SuccessCheck } from "../../assets/svg/success-feedback.svg";
import { ReactComponent as FailureCheck } from "../../assets/svg/failure-feedback.svg";
import { SuccessContainer } from "./style";
import { Col, Grid, Row } from "react-flexbox-grid";
import Button from "../Button";
import {useNavigate} from "react-router-dom";

const Feedback = ({ feedback }) => {
  const { title, explain, success, feedbackAction } = feedback;
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const hasActionToHandle = () => {
    if (!feedbackAction) {
      return false;
    }

    if (!feedbackAction.action || !feedbackAction.label) {
      return false;
    }

    return true;
  };

  return (
    <SuccessContainer>
      <Grid>
        <Row center="lg">
          <Col lg={3}>
            <Row center="lg">
              <h2>{title}</h2>
            </Row>
            <Row center="lg">
              {success ? (
                <SuccessCheck width="150" />
              ) : (
                <FailureCheck width="150" />
              )}
            </Row>
            <Row center="lg">
              <p>{explain}</p>
            </Row>
            {hasActionToHandle() && (
              <Row center="lg" style={{ marginTop: "2em" }}>
                <Button
                  size="large"
                  label={feedbackAction.label}
                  onClick={() => {
                    feedbackAction.action()
                  }}
                />
              </Row>
            )}
          </Col>
        </Row>
      </Grid>
    </SuccessContainer>
  );
};

export default Feedback;
