import { Row, Col } from "react-flexbox-grid";
import styled from "styled-components";

export const SignupContainer = styled.div`
  padding: 2em 0;
`;
export const FormRow = styled(Row)`
  margin-bottom: ${(props) => props.marginBottom || "1em"};

  @media (max-width: 991px) {
    .select + .select {
      margin-top: 1em;
    }
  }

  @media(max-width:768px) {
    h1 {
      font-size: ${(props) => props.theme.fontSizes.p1};
    }

    h2 {
      font-size: ${(props) => props.theme.fontSizes.h1};
    }
  }
`;

export const PasswordCol = styled(Col)`
  position: relative;
`;

export const ImageRow = styled(Row)`
  @media (max-width: 991px) {
    display: none;
  }
`;

export const TitleRow = styled(Row)`
  @media(max-width:768px) {
    h3 {
      font-size: ${(props) => props.theme.fontSizes.h2};
    }
    display: flex;
    justify-content: center;
  }
`
