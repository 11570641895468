import React from 'react'
import { Col, Grid, Row } from "react-flexbox-grid";
import ErrPageIcon from '../../assets/svg/error-page.svg'
import './style';
import { PageContainer } from './style';
import Button from '../../components/Button';
import { useTranslation } from 'react-i18next';

const ErrPage = () => {
  const { t } = useTranslation();
  return (
    <>
    <Row center="lg" style={{backgroundColor: "#c6c6c6"}}>
      <PageContainer>
        <img src={ErrPageIcon} />
      </PageContainer>
    </Row>
    <Row style={{justifyContent: 'center', backgroundColor: "#c6c6c6"}}>
      <Row center="lg" style={{backgroundColor: "#c6c6c6", width: '450px'}}>
        <p>{t('lbl_pls_try_again')}</p>
      </Row>
    </Row>
    <Row center="lg" style={{backgroundColor: "#c6c6c6", padding: "30px"}}>
      <Button 
      label={t('btn_try_again')}
      />
    </Row>
    </>
  )
}

export default ErrPage; 