import { Col, Row } from "react-flexbox-grid";
import styled from "styled-components";

export const LatamContainerCol = styled(Col)`
  background-color: white;
  display: flex;
  border-radius: 5px;
  align-items: center;
  flex-direction: column;
  box-shadow: rgba(100, 100, 111, 0.2) 0px -2px 5px 0px;
  margin: 6px 0;
  padding: 0 0.5rem;
  text-align: center;

  position: relative;
  overflow: hidden;
`;

export const ColorDots = styled(Row)`
  display: flex;
`;

export const ImgBlock = styled(Col)`
  margin-top: 30px
`;

export const HeadTittle = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
    & h3 {
      font-size: 18px;
      font-weight: 100;
      color: #404040;
    }
    & h2 {
      font-size: 25px;
      font-weight: 500;
      color: #404040;
      
    }
`;

export const PriceTag = styled(Row)`
    & h2 {
      font-size: 45px;
      color: #05036F;
    }
`;

export const ListedItems = styled(Col)`
    justify-content: center;
    height: 190px;
    & ul {
      font-size: 14px;
      margin-left: 65px;
      color: black;
      width: 68%;
    } 
    
`;
