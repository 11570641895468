import React from 'react';
import { Section, DivItens, ContentContainer, Person, PersonImage, PersonText } from './style';
import fotoDeuza from '../../../assets/svg/AboutUs/foto-deuza.jpg';
import fotoFernanda from '../../../assets/svg/AboutUs/foto-fernanda.jpg';
import Separate from '../../../assets/svg/separate-icon-service.svg';
import line from '../../../assets/svg/AboutUs/line-about-us.png'
import { useTranslation } from "react-i18next";

const HighLevel = () => {
  const { t } = useTranslation();
  const people = [
    {
      name: t("lbl_deuza_name"),
      image: fotoDeuza,
      text: t("lbl_deuza_description"),
    },
    {
      name: t("lbl_fernanda_name"),
      image: fotoFernanda,
      text: t("lbl_fernanda_description"),
    },
  ];

  return (
    <Section id="banner" className="bottom">
      <DivItens>
        <ContentContainer>
          {people.map((person, index) => (
            <DivItens key={index}>
              {person.name === 'Deuza Santos' &&  <img src={line} className='line'/>}
              <Person className={person.name === 'Fernanda Reis' ? 'fernanda-reis' : person.name === 'Deuza Santos' ? 'deuza' : ''}>
                <PersonImage src={person.image} alt={person.name} className={person.name === 'Fernanda Reis' ? 'fernanda-reis' : ''} />
                <PersonText dangerouslySetInnerHTML={{ __html: person.text }} />
              </Person>
              {person.name === 'Deuza Santos' && <img src={Separate} alt="Separator" />}
            </DivItens>
          ))}
        </ContentContainer>
      </DivItens>
    </Section>
  );
};

export default HighLevel;