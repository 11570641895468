import styled from "styled-components";

export const LoadScreenBlock = styled.div`
  position: fixed;
  top: 0; left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LoadLoop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 210px;
  }

`;

export const MessageContent = styled.div`
  padding: 2em;
  text-align: center;
  color: ${(props) => props.theme.colors.blue.dark};
`;
