import styled, { keyframes } from "styled-components";
import { Form } from "@unform/web";
import * as Toast from '@radix-ui/react-toast';
import { Col, Grid, Row } from "react-flexbox-grid";

import { Flex } from "../../shared/style";
import { getLabelColor } from "../../utils/selectColors";

export const DetailContainer = styled.div`
  /* height: calc(100vh - 70px); */
`;

export const HeadContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.light.default};
  padding-top: 50px;
  padding-bottom: 20px;
`;

export const GridWrapper = styled(Grid)`
  margin-top: 1.25em;

  .row-dOpyEt.svelte-f1k4kg {
    align-items: center;
  }

  @media (max-width: 768px) {
    padding: 0em 1em;
  }
`;

export const RowLine = styled.div`
  flex: 1;
  background-color: ${(props) => props.theme.colors.yellow.default};
  height: 5px;
  margin-bottom: 30px;
`;

export const BoxContainer = styled(Row)`
  width: 100vw;
  gap: 30px;
  margin: 0;
  justify-content: center;
`;

export const BoxList = styled(Row)`
  width: 50%;
`;

export const PlanList = styled(Col)`
  width: 45%;
  & li {
    font-size: 18px;
    margin-top: 12px;
  }

  @media (max-width: 768px) {
    li {
      font-size: 16px;
    }
  }
`;

export const GridContent = styled(Row)`
  margin-top: 25px;
  margin-bottom: 50px;

  & input {
    padding: 10px;
  }

  @media (max-width: 768px) {
    .col-md-12 + .col-md-12,
    .col-sm-12 + .col-sm-12 {
      margin-top: 3em;
    }
  }
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 1.25em;
`;

export const RowForm = styled(Row)`
  @media (max-width: 420px) {
    gap: 1.25em;
  }
`;

export const RowSelect = styled(Row)`
  margin-bottom: 2em;
`;

export const ColEmail = styled(Col)`
  font-size: 13px;
  margin-top: 0.5em;
  color: ${(props) => props.theme.colors.black.light};

  a {
    margin-left: 0.3em;
    white-space: nowrap;
  }
`;

export const PricePlan = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  h1 {
    color: ${(props) => props.theme.colors.seaGreen.default};
    font-size: ${(props) => props.theme.fontSizes.h3};
    font-weight: 500;
  }

  @media (max-width: 640px) {
    display: block;
  }

  @media (max-width: 420px) {
    display: block;
    margin: 1em 0 0;

    h3 {
      font-size: ${(props) => props.theme.fontSizes.h1};
    }

    h1 {
      font-size: ${(props) => props.theme.fontSizes.h3};
    }
  }
`;

export const RadioContainer = styled(Row)`
  gap: 20px;
  margin-left: 2px;
  margin-top: 15px;
`;

export const BackBtn = styled.button`
  cursor: pointer;
  border: solid 2px ${(props) => props.theme.colors.seaGreen.default};
  background: none;
  padding: 8px;
  border-radius: 6px;
  color: ${(props) => props.theme.colors.seaGreen.default};
  /* font-size: 17px; */
  font-weight: 700;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;

  transition: background-color 0.2s;

  &:hover {
    background-color: ${(props) => props.theme.colors.blue.dark};
  }
`;

export const DefaultSelect = styled(Flex)`
  flex-direction: column;
  width: 100%;

  & svg {
    outline: none;
    margin-left: 0.25em;
  }

  & p {
    color: ${(props) => getLabelColor(props)};
  }

  & small {
    margin-top: 0.5em;
    color: ${(props) => getLabelColor(props)};
    text-align: start;
  }
`;

const VIEWPORT_PADDING = 25;

export const ToastViewport = styled(Toast.Viewport)`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: ${VIEWPORT_PADDING}px;
  gap: 10px;
  width: 390px;
  max-width: 100vh;
  margin: 0;
  list-style: none;
  z-index: 2147483647;
  outline: none;
`;

const hide = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(calc(100% + ${VIEWPORT_PADDING}px));
  }
  to {
    transform: translateX(0)
  }
`;

const swipeOut = keyframes`
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + ${VIEWPORT_PADDING}px));
  }
`;

export const ToastRoot = styled(Toast.Root)`
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  padding: 15;
  display: grid;
  grid-template-columns: auto max-content;
  column-gap: 15px;
  align-items: center;

  &[data-state="open"] {
    animation: ${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  &[data-state="closed"] {
    animation: ${hide} 100ms ease-in;
  }
  &[data-swipe="move"] {
    transform: translateX(var(--radix-toast-swipe-move-x));
  }
  &[data-swipe="cancel"] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
  }
  &[data-swipe="end"] {
    animation: ${swipeOut} 100ms ease-out;
  }
`;
