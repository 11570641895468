import { StyledComponent } from "styled-components";
import styled from "styled-components";

export const DisagreeBtn = styled.button`
  padding: 8px;
  color: #05036F;
  border: solid 3px #05036F;
  background: white;
  border-radius: 5px;
  cursor: pointer;
    &:hover {
    border: solid 3px #00B6BA;
    }
    &:active {
      border: solid 3px #05036F;
    }
`;