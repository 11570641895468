import React from "react";
import comLupa from "../../assets/svg/home/high-level/Menino-com-Lupa.png"
import HighLevel from "../../components/Home/high-level";
import LowLevel from "../../components/Home/mid-level";
import Plataforma from "../../components/Home/mid-level-infos";
import Company from "../../components/Home/company";
import FAQ from "../../components/Home/faq";
import { useTranslation } from "react-i18next";
import Banner from '../../assets/svg/home/high-level/bg-azul.png'
import Separate from '../../assets/svg/AboutUs/separate-icon-service.svg'
const Home = () => {
  const { t } = useTranslation();
  const bannerData = [
    {
      thumbnailUrl: Banner,
      content: t('banner_content'),
      excerpt: t('banner_excerpt'),
      btnCadastre: t('banner_btn_cadastre'),
      btnPlanos: t('banner_btn_planos'),
      imgHeader: comLupa
    },
  ];

  return (
    <div>
        <HighLevel bannerData={bannerData} />
        <LowLevel/>
        <Plataforma/>
        <Company/>
        <FAQ/>
    </div>
  )
};

export default Home;
