// posts.js
import oneBlog from '../assets/svg/blog/1 - oneBlog.png'
import fourBlog from '../assets/svg/blog/4 - fourBlog.png'
import fiveBlog from '../assets/svg/blog/5 - fiveBlog.png'
import twoBlog from '../assets/svg/blog/2 - twoBlog.png'
import threeBlog from '../assets/svg/blog/3 - threeBlog.png' 
import { useTranslation } from "react-i18next";

export default function BlogInfos(){
  const { t } = useTranslation();

 return  [  
    {
      title: t("title_patent_document"),
      date: t("date_september_2024"),
      author: t("author_ana_carolina"),
      techRevisor: t("tech_revisor_deuzanira_santos"),
      PortugueseRevisor: t("portuguese_revisor_janiara_de_lima_medeiros"),
      image: oneBlog,
      tags: [t("tag_document"), t("tag_importance"), t("tag_patent")],
      content: `
      <p>${t('lbl_blogpost_paragraph1')}</p>
      <p>${t('lbl_blogpost_paragraph2')}</p>
      <p>${t('lbl_blogpost_paragraph3')}</p>
      <p>${t('lbl_blogpost_paragraph4')}</p>
      <p>${t('lbl_blogpost_paragraph5')}</p>
      <p>${t('lbl_blogpost_paragraph6')}</p>
      <p>${t('lbl_blogpost_paragraph7')}</p>
      <p>${t('lbl_blogpost_paragraph8')}</p>
      <p>${t('lbl_blogpost_paragraph9')}</p>
      <ol>
        <li>${t('lbl_blogpost_list_item1')}</li>
        <li>${t('lbl_blogpost_list_item2')}</li>
        <li>${t('lbl_blogpost_list_item3')}</li>
        <li>${t('lbl_blogpost_list_item4')}</li>
        <li>${t('lbl_blogpost_list_item5')}</li>
      </ol>
    `,
    },
    {
        title: t("what_is_the_best_type_of_search_according_to_my_business_objective"),
        date: t("november_2023"),
        author: 'Ana Carolina Pereira',
        techRevisor: t("tech_revisor_deuzanira_santos"),
        PortugueseRevisor: t("portuguese_revisor_janiara_de_lima_medeiros"),
        image: twoBlog,
        tags: [t("state_of_the_art"), t("freedom_to_operate"), t("search")],
        content: `
        <p>${t('lbl_blogpost_situation1')}</p>
        <p>${t('lbl_blogpost_situation2')}</p>
        <p>${t('lbl_blogpost_situation3')}</p>
        <p>${t('lbl_blogpost_situation4')}</p>
        <p>${t('lbl_blogpost_situation5')}</p>
        <p>${t('lbl_blogpost_situation6')}</p>
        <p>${t('lbl_blogpost_situation7')}</p>
        <p>${t('lbl_blogpost_situation8')}</p>
        <p>${t('lbl_blogpost_situation9')}</p>
        <p>${t('lbl_blogpost_situation10')}</p>
        <p>${t('lbl_blogpost_situation11')}</p>
        <p>${t('lbl_blogpost_situation12')}</p>
        <p>${t('lbl_blogpost_situation13')}</p>
        <p>${t('lbl_blogpost_situation14')}</p>
      `,      
      },
      {
        title: t("cost_of_insufficient_patent_information_search"),
        date: t("december_2023"),
        author: 'Ana Carolina Pereira',
        techRevisor: t("tech_revisor_deuzanira_santos"),
        PortugueseRevisor: t("portuguese_revisor_janiara_de_lima_medeiros"),
        image: threeBlog,
        tags: [t("investments"), t("r_and_d"), t("competitive_advantage"), t("patent")],
        content: `
        <p>${t('lbl_patent_saving1')}</p>
        <p>${t('lbl_patent_saving2')}</p>
        <p>${t('lbl_patent_saving3')}</p>
        <p>${t('lbl_patent_saving4')}</p>
        <p>${t('lbl_patent_saving5')}</p>
        <p>${t('lbl_patent_saving6')}</p>
        <p>${t('lbl_patent_saving7')}</p>
        <p>${t('lbl_patent_saving8')}</p>
        <p>${t('lbl_patent_saving9')}</p>
      `,
      },
      {
        title: t("how_to_use_technological_information_from_patent_document_for_corporate_decisions"),
        date: t("january_2024"),
        author: 'Ana Carolina Pereira',
        techRevisor: t("tech_revisor_deuzanira_santos"),
        PortugueseRevisor: t("portuguese_revisor_janiara_de_lima_medeiros"),
        image: fourBlog,
        tags: [t("competitiveness"), t("competition"), t("strategy"), t("information")],
        content: `
          <p>${t('lbl_patent_competition1')}</p>
          <p>${t('lbl_patent_competition2')}</p>
          <p>${t('lbl_patent_competition3')}</p>
          <p>${t('lbl_patent_competition4')}</p>
          <p>${t('lbl_patent_competition5')}</p>
          <p>${t('lbl_patent_competition6')}</p>
          <p>${t('lbl_patent_competition7')}</p>
          <p>${t('lbl_patent_competition8')}</p>
      `,
      },
      {
        title: t("patent_classification"),
        date: t("february_2024"),
        author: 'Autoria: Ana Carolina Pereira',
        techRevisor: t("tech_revisor_deuzanira_santos"),
        PortugueseRevisor: t("portuguese_revisor_janiara_de_lima_medeiros"),
        image: fiveBlog,
        tags: [t("ipc"), t("cpc"), t("classification"), t("search")],
        content: `
            <p>${t('lbl_library1')}</p>
            <p>${t('lbl_library2')}</p>
            <p>${t('lbl_library3')}</p>
            <p>${t('lbl_library4')}</p>
            <p>${t('lbl_library5')}</p>
            <p>${t('lbl_library6')}</p>
            <p>${t('lbl_library7')}</p>
            <p>${t('lbl_library8')}</p>
            <p>${t('lbl_library9')}</p>
            <p>${t('lbl_library10')}</p>
          `

      }
    ];


}
  

  