import React from 'react';
import { Form } from "@unform/web";
import { useTranslation } from "react-i18next";
import { Grid, Col, Row } from "react-flexbox-grid";

import Panel from "../../components/Panel";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Select from "../../components/Select";
import { RowWrapper, ContentContainer } from "../../shared/style";
import ComputerImg from "../../assets/svg/computer.svg"
import { ContactUsContainer, Textarea, FormRow, ImageRow, TextareaRow } from "./style";

const Support = () => {
  const { t } = useTranslation();
  const supportOptions = [
    { label: t("lbl_support_access"), value: "acesso" },
    { label: t("lbl_support_register"), value: "cadastro" },
    { label: t("lbl_support_plan"), value: "plano" },
    { label: t("lbl_support_history"), value: "histórico de buscas" },
    { label: t("lbl_support_map"), value: "mapa" },
    { label: t("lbl_support_report"), value: "relatórios" },
  ];

  return (
    <ContactUsContainer>
      <ContentContainer>
        <Grid>
          <RowWrapper middle="md">
            <Col lg={6}>
              <Row start="lg" center="xs">
                <Col xs={10} md={10} lg={10}>
                  <FormRow middle="md" center="xs" marginBottom="2em">
                    <Col>
                      <h3>{t("page_support_doubt")}</h3>
                      <p>{t("page_contact_content")}</p>
                    </Col>
                  </FormRow>

                  <Panel>
                    <Grid>
                      <Form>
                        <FormRow>
                          <Col xs={12} md={12} lg={12}>
                            <Input
                              placeholder={t("ph_signup_name")}
                              label={t("lbl_full_name")}
                              name="name"
                            />
                          </Col>
                        </FormRow>

                        <FormRow>
                          <Col xs={12} md={12} lg={12}>
                            <Input
                              placeholder={t("ph_signup_email")}
                              label={t("lbl_email")}
                              name="email"
                            />
                          </Col>
                        </FormRow>

                        <FormRow width="40%">
                          <Col xs={12} md={12} lg={12}>
                            <Select
                              placeholder={t("ph_support_problem")}
                              label={t("lbl_problem")}
                              name="problem"
                              options={supportOptions}
                            />
                          </Col>
                        </FormRow>

                        <TextareaRow>
                          <Col xs={12} md={12} lg={12}>
                            <label htmlFor="contact_message">{t("lbl_contact_message")}</label>
                            <Textarea
                              id="contact_message"
                              maxlength="500"
                              placeholder={t("ph_contact_message")}
                            />
                          </Col>
                        </TextareaRow>

                        <Row center="xs">
                          <Button
                            type="submit"
                            size="large"
                            label={t("lbl_send")}
                            disabled={false}
                          />
                        </Row>
                      </Form>
                    </Grid>
                  </Panel>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <ImageRow center="xs" end="lg">
                <img src={ComputerImg} alt="Computer" />
              </ImageRow>
            </Col>
          </RowWrapper>
        </Grid>
      </ContentContainer>
    </ContactUsContainer>
  );
}

export default Support;