import styled from "styled-components";

export const DefaultCheckbox = styled.label`
  display: block;
  position: relative;
  padding-left: 1.5em;
  cursor: pointer;
  user-select: none;
  color: ${(props) => props.disabled && props.theme.colors.light.dark};

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ span:after {
      display: block;
    }
  }

  & span {
    position: absolute;
    top: 2px;
    left: 0;
    height: 1em;
    width: 1em;
    background-color: ${(props) => props.theme.colors.light.default};
    border-radius: 4px;
    border: 2px solid
      ${(props) =>
        props.disabled
          ? props.theme.colors.light.dark
          : props.theme.colors.blue.default};

    &::after {
      content: "";
      position: absolute;
      display: none;
    }

    &::after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 60%;
      height: 60%;
      border-radius: 2px;
      background-color: ${(props) =>
        props.disabled
          ? props.theme.colors.light.dark
          : props.theme.colors.seaGreen.default};
    }
  }
`;
