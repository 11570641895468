import styled from "styled-components";
import { Flex } from "../../shared/style";
import * as ScrollArea from "@radix-ui/react-scroll-area";

export const Table = styled.table`
  width: 100%;
  font-size: ${(props) => props.theme.fontSizes.p2};
  border-spacing: 0;
  margin-top: 1em;
`;

export const Th = styled.th`
  padding: 1em 0;
  font-size: ${(props) => props.theme.fontSizes.p1};
  font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const Thead = styled.thead`
  background-color: ${(props) => props.theme.colors.light.default};
`;

export const Tr = styled.tr`
  &:nth-of-type(even) {
    background-color: ${(props) => props.theme.colors.light.default};
  }
`;

export const Td = styled.td`
  padding: 1em;
  &:not(:first-child, :last-child) {
    padding: 1em 0;
  }

  & p {
    font-size: ${(props) => props.theme.fontSizes.p2};
  }

  & svg {
    margin-right: 0.5em;
  }
`;

export const Tbody = styled.tbody`
  ${Td} {
    text-align: center;
  }
  ${Td}:first-child {
    text-align: left;
  }
`;

export const LogicType = styled.span`
  display: inline-block;
  padding: 0.25em;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.theme.colors.light.default};
  max-width: max-content;
  border-radius: 4px;
  margin: 0 0.3em;
`;

export const PaginationContainer = styled(Flex)`
  padding-top: 1em;
  align-items: center;
  justify-content: center;

  & button {
    margin: 0 0.5em;
  }
`;

export const ScrollAreaRoot = styled(ScrollArea.Root)`
  width: 100%;
`;

export const ScrollAreaViewport = styled(ScrollArea.Viewport)`
  width: 100%;
  overflow-x: auto;
`

export const ScrollAreaScrollbar = styled(ScrollArea.Scrollbar)`
  display: flex;
  height: 0.25em;
  touch-action: none;
  user-select: none;
  background-color: ${(props) => props.theme.colors.light.dark};
`;

export const ScrollAreaThumb = styled(ScrollArea.Thumb)`
  position: relative;
  flex: 1;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.black.light};
`;
