import React, { useState } from "react";
import Logo321GO from "../../assets/svg/321GO-logo.svg";
import LanguageMenu from "../LanguageMenu";
import { useTranslation } from "react-i18next";
import { RowWrapper } from "../../shared/style";
import SettingsMenu from "../SettingsMenu";
import { Col, Row } from "react-flexbox-grid";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { Menu } from "react-feather";
import {
  HeaderNav,
  RowHeader,
  HeaderLink,
  MenuButton,
  MenuColWrapper,
  HeaderContainer,
  HeaderColWrapper,
} from "./style";

const Header = () => {
  const { t } = useTranslation();
  const { authenticated } = useAuth();
  const [openMenu, setOpenMenu] = useState(false);

  const handleToggleMenu = () => {
    setOpenMenu(!openMenu);
  }

  const headerLinks = [
    {
      show: !authenticated,
      component: (key) => (
        <HeaderNav key={key} to="/">
          {t("lbl_home")}
        </HeaderNav>
      ),
    },
    // ABOUT US
       {
      show: !authenticated,
      component: (key) => (
        <HeaderNav key={key} to="/about-us">
          {t("lbl_who_we_are")}
        </HeaderNav>
      ),
    },
    {
      show: !authenticated,
      component: (key) => (
        <HeaderNav key={key} to="/our_plans">
          {t("lbl_our_plans")}
        </HeaderNav>
      ),
    },
    // BLOG
    {
      show: !authenticated,
      component: (key) => (
        <HeaderNav key={key} to="/blog">
          {t("lbl_content")}
        </HeaderNav>
      ),
    },

    // {
    //   show: !authenticated,
    //   component: (key) => (
    //     <HeaderNav key={key} to="/signup">
    //       {t("lbl_registration")}
    //     </HeaderNav>
    //   ),
    // },
    {
      show: !authenticated,
      component: (key) => (
        <HeaderNav key={key} to="/login">
          {t("lbl_login")}
        </HeaderNav>
      ),
    },
    {
      show: !authenticated,
      component: (key) => (
        <HeaderNav key={key} to="/contact_us">
          {t("lbl_contact_us")}
        </HeaderNav>
      ),
    },
    {
      show: authenticated,
      component: (key) => (
        <HeaderNav key={key} to="/dashboard">
          {t("lbl_dashboard")}
        </HeaderNav>
      ),
    },
    {
      show: authenticated,
      component: (key) => (
        <HeaderNav key={key} to="/subscription">
          {t("lbl_subscription")}
        </HeaderNav>
      ),
    },
    {
      show: authenticated,
      component: (key) => (
        <HeaderNav key={key} to="/search">
          {t("lbl_search")}
        </HeaderNav>
      ),
    },
    {
      show: authenticated,
      component: (key) => (
        <HeaderNav key={key} to="/support">
          {t("lbl_support")}
        </HeaderNav>
      ),
    },
  ];

  return (
    <HeaderContainer fluid>
      <RowWrapper middle="md">
        <Col xs>
          <Row center="lg">
            {authenticated ? (
              <NavLink to={"/dashboard"}>
                <img src={Logo321GO} alt="321Go Logo" />
              </NavLink>
            ) : (
              <NavLink to={"/"}>
                <img src={Logo321GO} alt="321Go Logo" />
              </NavLink>
            )}
          </Row>
        </Col>

        <MenuColWrapper xs>
          <MenuButton onClick={() => handleToggleMenu()}>
            <Menu width={28} height={28} />
          </MenuButton>
        </MenuColWrapper>

        <HeaderColWrapper active={openMenu} lg={10}>
          <Row between="xs">
            <Col lg={10} md={12}>
              <RowHeader active={openMenu} center="lg">
                {headerLinks.map((item, index) => {
                  if (item.show) {
                    return item.component(index);
                  }

                  return null;
                })}
              </RowHeader>
            </Col>
            <Col lg={2} md={0}>
              <Row center="lg">
                <LanguageMenu />
                {authenticated && <SettingsMenu />}
              </Row>
            </Col>
          </Row>
        </HeaderColWrapper>
      </RowWrapper>
    </HeaderContainer>
  );
};

export default Header;
