import styled from "styled-components";
import { getButtonBackground, getButtonColor } from "../../utils/buttonColors";

export const DefaultButton = styled.button`
  width: ${(props) => props.width || "max-content"};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6em;
  cursor: pointer;
  border: unset;
  border-radius: 4px;
  border: 2px solid
    ${(props) =>
      props.buttonStyle === "outlined"
        ? props.theme.colors.blue.default
        : "transparent"};
  color: ${(props) => getButtonColor(props)};
  background-color: ${(props) => getButtonBackground(props)};

  & p {
    font-size: ${(props) =>
      props.size === "large"
        ? props.theme.fontSizes.p1
        : props.theme.fontSizes.p2};
  }

  &:hover:not(:disabled):not(:active) {
    border: 2px solid ${(props) => props.theme.colors.seaGreen.default};
    text-shadow: 0 0 0.6px ${(props) => props.theme.colors.blue.default};
  }

  &:active:not(:disabled) {
    color: ${(props) => props.theme.colors.seaGreen.default};
    background-color: ${(props) => props.theme.colors.blue.dark};
    text-shadow: 0 0 0.6px ${(props) => props.theme.colors.seaGreen.default};

    & svg {
      stroke: ${(props) => props.theme.colors.seaGreen.default};
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: ${(props) =>
      !props.buttonStyle
        ? props.theme.colors.light.default
        : props.theme.colors.light.dark};
    border-color: ${(props) =>
      props.buttonStyle === "outlined"
        ? props.theme.colors.light.dark
        : "transparent"};
    background-color: ${(props) =>
      props.buttonStyle === "outlined" && "transparent"};

    & svg {
      stroke: ${(props) =>
        props.buttonStyle === "outlined"
          ? props.theme.colors.light.dark
          : props.theme.colors.light.default};
    }
  }
`;
