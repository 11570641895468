import React from "react";
import { useTranslation } from "react-i18next";
import IconButton from "../IconButton";
import Panel from "../Panel";
import { ReactComponent as CloseIcon } from "../../assets/svg/close-icon.svg";
import Modal from "../Modal";

const ModalContent = ({ isOpen, closeModal, header, content }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={{}} >
      <Panel
        header={t(header)}
        margin="0 0 1em 0"
        action={
          <IconButton icon={CloseIcon} onClick={closeModal} size="large" />
        }
        style={{ width: "50%" }}
      >
        <p style={{ marginTop: "1em" }}>{t(content)}</p>
      </Panel>
    </Modal>
  );
};

export default ModalContent;
