import React from "react";
import { DefaultCheckbox } from "./style";

const Checkbox = ({ label, disabled, checked = false, onChange, afterCheckLabel = () => { return ""} }) => {
  return (
    <DefaultCheckbox disabled={disabled}>
      {label}
      <input type="checkbox" checked={checked} disabled={disabled} onChange={onChange} />
        {afterCheckLabel}
      <span></span>
    </DefaultCheckbox>
  );
};

export default Checkbox;
