import React from "react";
import theme from "../../shared/theme";
import { DefaultIconButton } from "./style";

const IconButton = ({ onClick, icon, size, disabled, ...props }) => {
  const Icon = icon ? icon : null;
  return (
    <DefaultIconButton disabled={disabled} {...props} onClick={onClick}>
      {Icon && (
        <Icon
          width={size === "large" ? "24" : "16"}
          height={size === "large" ? "24" : "16"}
          stroke={theme.colors.blue.default}
        />
      )}
    </DefaultIconButton>
  );
};

export default IconButton;
