import React from "react";
import { DefaultInTextLink } from "./style";

const InTextLink = ({ label, to, fontSize, fontWeight }) => {
  return (
    <DefaultInTextLink fontSize={fontSize} fontWeight={fontWeight} to={to}>
      {label}
    </DefaultInTextLink>
  );
};

export default InTextLink;
