import axios from "axios";
import { Col, Row } from "react-flexbox-grid";
import { useTranslation } from "react-i18next";

import Input from "../../components/Input";
import Button from "../../components/Button";
import InTextLink from "../../components/InTextLink";
import RadioButton from "../../components/RadioButton";
import { RowForm, ColEmail, FormContainer, RadioContainer } from "./style";

export const TicketForm = ({ formRef, validateFieldsToSubmit, userData, setUserData, delayTimer }) => {
  const { t } = useTranslation();

  const cpf_format = (cpf) => {
    cpf = cpf.replace(/[^\d]/g, "");

    if (cpf.length <= 3) cpf = cpf.replace(/(\d{3})/, "$1");
    else if (cpf.length >= 3 && cpf.length <= 6) cpf = cpf.replace(/(\d{3})?(\d{3})?/, "$1.$2");
    else if (cpf.length >= 5 && cpf.length <= 8) cpf = cpf.replace(/(\d{3})?(\d{3})?(\d{3})?/, "$1.$2.$3");
    else cpf = cpf.replace(/(\d{3})?(\d{3})?(\d{3})?(\d{2})/, "$1.$2.$3-$4");
    return cpf;
  };

  const cnpj_format = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]/g, "");

    if (cnpj.length <= 2) cnpj = cnpj.replace(/(\d{2})/, "$1");
    else if (cnpj.length > 2 && cnpj.length <= 5) cnpj = cnpj.replace(/(\d{2})?(\d{3})?/, "$1.$2");
    else if (cnpj.length >= 6 && cnpj.length <= 9)
      cnpj = cnpj.replace(/(\d{2})?(\d{3})?(\d{3})?/, "$1.$2.$3");
    else if (cnpj.length >= 12 && cnpj.length <= 13)
      cnpj = cnpj.replace(/(\d{2})?(\d{3})?(\d{3})?(\d{4})?/, "$1.$2.$3/$4");
    else cnpj = cnpj.replace(/(\d{2})?(\d{3})?(\d{3})?(\d{4})?(\d{2})/, "$1.$2.$3/$4-$5");

    return cnpj;
  };

  return (
    <FormContainer ref={formRef} onSubmit={validateFieldsToSubmit} style={{ width: "100%" }}>
      <RowForm>
        <Col xs={12}>
          <Input
            placeholder={t("ph_signup_name")}
            label={t("lbl_full_name")}
            name="name"
            onInput={(e) => {
              setUserData({ ...userData, name: e.target.value });
            }}
          />
        </Col>
      </RowForm>

      <RowForm>
        <Col xs={12} sm={4}>
          <p>{t("lbl_type_of_person")}</p>

          <RadioContainer>
            <RadioButton
              label={t("lbl_physical")}
              checked={userData.type_person == "fisica"}
              onClick={() => {
                setUserData({ ...userData, type_person: "fisica", cnpj_cpf: "" });
              }}
            />

            <RadioButton
              label={t("lbl_entity")}
              checked={userData.type_person == "juridica"}
              onClick={() => setUserData({ ...userData, type_person: "juridica", cnpj_cpf: "" })}
            />
          </RadioContainer>
        </Col>
        <Col xs={12} sm={8}>
          <Input
            placeholder={t("lbl_CPF")}
            label={"CPF"}
            name="cnpj_cpf"
            onInput={(event) => {
              let cpf_cnpj = event.target.value;

              if (userData.type_person == "fisica")
                setUserData({
                  ...userData,
                  cnpj_cpf: cpf_format(cpf_cnpj),
                });
              else {
                setUserData({
                  ...userData,
                  cnpj_cpf: cnpj_format(cpf_cnpj),
                });
              }
            }}
            value={userData.cnpj_cpf}
          />
        </Col>
      </RowForm>

      <RowForm>
        <Col xs={12} sm={4}>
          <Input
            placeholder={t("lbl_CEP")}
            label={t("ph_CEP")}
            name="zipcode"
            onInput={(e) => {
              setUserData({
                ...userData,
                zipcode: e.target.value,
              });
              clearTimeout(delayTimer);
              delayTimer = setTimeout(async function () {
                try {
                  const url = `https://viacep.com.br/ws/${e.target.value}/json/`;
                  const { data } = await axios.get(url);
                  setUserData({
                    ...userData,
                    zipcode: e.target.value,
                    address: data.logradouro,
                    num_address: "",
                    neighborhood: data.bairro,
                    city: data.localidade,
                    state: data.uf,
                  });
                } catch (error) {
                  setUserData({
                    ...userData,
                    address: "",
                    num_address: "",
                    neighborhood: "",
                    city: "",
                    state: "",
                  });
                }
              }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
            }}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            placeholder={t("ph_address")}
            label={t("lbl_address")}
            name="address"
            value={userData.address}
            onInput={(e) => setUserData({ ...userData, address: e.target.value })}
          />
        </Col>
        <Col xs={12} sm={2}>
          <Input
            label={t("lbl_number")}
            name={"num_address"}
            onInput={(e) => setUserData({ ...userData, num_address: e.target.value })}
          />
        </Col>
      </RowForm>

      <RowForm>
        <Col xs={12} sm={4}>
          <Input
            placeholder={t("ph_district")}
            label={t("lbl_district")}
            name="neighborhood"
            value={userData.neighborhood}
            onInput={(e) => setUserData({ ...userData, neighborhood: e.target.value })}
          />
        </Col>
        <Col xs={12} sm={4}>
          <Input
            placeholder={t("ph_city")}
            label={t("lbl_city")}
            name="city"
            value={userData.city}
            onInput={(e) => setUserData({ ...userData, city: e.target.value })}
          />
        </Col>
        <Col xs={12} sm={4}>
          <Input
            placeholder={t("ph_state")}
            label={t("lbl_state")}
            name="state"
            value={userData.state}
            onInput={(e) => setUserData({ ...userData, state: e.target.value })}
          />
        </Col>
      </RowForm>

      <Row>
        <Col xs={12}>
          <Input
            placeholder={t("ph_email")}
            label={"E-mail"}
            type={"email"}
            name="email"
            onInput={(e) => setUserData({ ...userData, email: e.target.value })}
          />
        </Col>

        <ColEmail xs={12}>
          <span>
            {t("lbl_insert_321_email")} {t("lbl_not_member")}
          </span>
          <InTextLink fontWeight={"regular"} label={t("page_signup_signup_here")} to="/signup" />
        </ColEmail>
      </Row>

      <Row style={{ justifyContent: "center", margin: "30px" }}>
        <Button type="submit" label={t("lbl_generate_ticket")} />
      </Row>
    </FormContainer>
  );
};
