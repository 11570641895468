import React from "react";
import { Row } from "react-flexbox-grid";
import { ImgBlock, HeadTittle, PriceTag, ListedItems, LatamContainerCol } from "./style";
import ColorDots from "../../ColorDots";
import Button from "../../Button";
import { useTranslation } from "react-i18next";
// import { useDispatch } from "react-redux";
// import { addPlan } from "../../../planSlice";

const Latam = ({ id, name, price, map, description, price_test, unit_price }) => {
  const { t } = useTranslation();
  // const dispatch = useDispatch();

  const handleHire = () => {
    localStorage.setItem(
      "planStorage",
      JSON.stringify({
        id: id,
        name: t(name),
        price: t(price),
        price_test: price_test,
        unit_price: unit_price,
        description: description,
      }),
    );
  };

  return (
    <LatamContainerCol xs className="keen-slider__slide">
      <ImgBlock>
        <img src={map} alt="" />
      </ImgBlock>

      <div style={{ display: "Flex", alignItems: "center", flexDirection: "column", gap: "20px" }}>
        <HeadTittle>
          <h3>{t("lbl_passport")}</h3>
          <h2>{t(`${name}`)}</h2>
        </HeadTittle>

        <ColorDots />

        <PriceTag>
          <h2>{price}</h2>
        </PriceTag>
        <ListedItems>
          <ul>
            {description.map((item) => {
              return <li key={item}>{t(item).replace("%", price_test)}</li>;
            })}
          </ul>
        </ListedItems>
        <Row style={{ margin: "20px" }}>
          <Button
            label={t("lbl_hire_now")}
            // onClick={() => {
            //   dispatch(addPlan({ name, price, description, price_test, unit_price }));
            // }}
            onClick={() => handleHire()}
            to="/plan_detail"
            clickAndNavigateMode={true}
          />
        </Row>
      </div>
    </LatamContainerCol>
  );
};
export default Latam;
