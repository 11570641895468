import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Section, Container, Row, Col, BlogImage, BlogTitle, BlogMeta, BlogContent, BackButton } from './style';
import BlogInfos from '../../../api/posts';
import { useTranslation } from "react-i18next";

const BlogPost = () => {
  const { t } = useTranslation();

  const { postId } = useParams();
  const navigate = useNavigate();
  const blogInfos = BlogInfos();
  const post = blogInfos[postId];
  console.log(post);

  if (!post) {
    return <div>Post não encontrado</div>;
  }

  return (
    <Section>
      <Container>
        <Row>
          <Col>
            <BackButton onClick={() => navigate('/blog')}>{t("lbl_back")}</BackButton>
            <BlogTitle>{post.title}</BlogTitle>
            <BlogImage src={post.image} alt={post.title} />
            <BlogMeta>{post.author}, {post.date}</BlogMeta>
            <BlogContent dangerouslySetInnerHTML={{ __html: post.content }} />
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default BlogPost;