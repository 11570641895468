import styled from "styled-components";
import { Row } from "react-flexbox-grid";

import Panel from "../../components/Panel";
import { ContentContainer } from "../../shared/style";

export const SearchContainer = styled(ContentContainer)`
  align-items: flex-start;
  padding-top: 2em;
  padding-bottom: 1em;
  /* height: calc(100vh - 118px); */
`;

export const SearchPanel = styled(Panel)`
  border: 2px solid ${(props) => props.theme.colors.blue.default};
  background-color: ${(props) => props.theme.colors.light.default};
  text-align: start;
`;

export const SearchExplain = styled.p`
  font-size: ${(props) => props.theme.fontSizes.p2};
  margin-top: 1em;
`;

export const PlanWarning = styled.h1`
  text-align: left;
  font-weight: bold;
  padding: 1rem 0;

  a {
    color: ${(props) => props.theme.colors.seaGreen.default};
  }
`;

export const RowForm = styled(Row)`
  @media (max-width: 768px) {
    gap: 2em;
  }
`;