import theme from "../shared/theme";

export const getSelectColor = (props) => {
  if (props.state.isDisabled) {
    return theme.colors.black.default;
  }

  if (props.error) {
    return theme.colors.sweetRed.default;
  }

  return theme.colors.blue.default;
};

export const getSelectBorderColor = (props) => {
  if (props.state.isDisabled) {
    return theme.colors.light.dark;
  }

  if (props.error) {
    return theme.colors.sweetRed.default;
  }

  return theme.colors.blue.default;
};

export const getLabelColor = (props) => {
  if (props.error) {
    return theme.colors.sweetRed.dark;
  }

  if (props.disabled) {
    return theme.colors.light.dark;
  }

  return theme.colors.black.default;
};
